// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .template-container {
  position: relative;
  border-radius: 22px;
}

.template-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 22px 22px 0 0;
}

.template-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 22px;
  background-size: cover;
  background-position: top;


} */


.preview-image-50px {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 15px;
}
.preview-image-50px img {
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1bW1hcnktaXRlbS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7R0FxQkc7OztBQUdIO0VBQ0UsZUFBZTtFQUNmLGdCQUFnQjtFQUNoQixnQkFBZ0I7RUFDaEIsbUJBQW1CO0FBQ3JCO0FBQ0E7RUFDRSxXQUFXO0FBQ2IiLCJmaWxlIjoic3VtbWFyeS1pdGVtLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiAudGVtcGxhdGUtY29udGFpbmVyIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBib3JkZXItcmFkaXVzOiAyMnB4O1xufVxuXG4udGVtcGxhdGUtaW5mbyB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgYm90dG9tOiAwO1xuICB3aWR0aDogMTAwJTtcbiAgYm9yZGVyLXJhZGl1czogMjJweCAyMnB4IDAgMDtcbn1cblxuLnRlbXBsYXRlLWltYWdlIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aWR0aDogMTAwJTtcbiAgcGFkZGluZy1ib3R0b206IDEwMCU7XG4gIGJvcmRlci1yYWRpdXM6IDIycHg7XG4gIGJhY2tncm91bmQtc2l6ZTogY292ZXI7XG4gIGJhY2tncm91bmQtcG9zaXRpb246IHRvcDtcblxuXG59ICovXG5cblxuLnByZXZpZXctaW1hZ2UtNTBweCB7XG4gIG1heC13aWR0aDogNTBweDtcbiAgbWF4LWhlaWdodDogNTBweDtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgYm9yZGVyLXJhZGl1czogMTVweDtcbn1cbi5wcmV2aWV3LWltYWdlLTUwcHggaW1nIHtcbiAgd2lkdGg6IDEwMCU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/wizard-summary/summary-item/summary-item.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;GAqBG;;;AAGH;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;;AAEA,wjCAAwjC","sourcesContent":["/* .template-container {\n  position: relative;\n  border-radius: 22px;\n}\n\n.template-info {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  border-radius: 22px 22px 0 0;\n}\n\n.template-image {\n  position: relative;\n  width: 100%;\n  padding-bottom: 100%;\n  border-radius: 22px;\n  background-size: cover;\n  background-position: top;\n\n\n} */\n\n\n.preview-image-50px {\n  max-width: 50px;\n  max-height: 50px;\n  overflow: hidden;\n  border-radius: 15px;\n}\n.preview-image-50px img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
