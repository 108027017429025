// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-effect:hover {
  color: rgb(128, 122, 122);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm8tdGlwLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSx5QkFBeUI7QUFDM0IiLCJmaWxlIjoiaW5mby10aXAuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pY29uLWVmZmVjdDpob3ZlciB7XG4gIGNvbG9yOiByZ2IoMTI4LCAxMjIsIDEyMik7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/info-tip/info-tip.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA,wUAAwU","sourcesContent":[".icon-effect:hover {\n  color: rgb(128, 122, 122);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
