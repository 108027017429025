import { Component, OnInit, Input } from '@angular/core';
import { MoneyModel } from 'src/app/models/sales-funnel';

@Component({
  selector: 'app-money-model-preview',
  templateUrl: './money-model-preview.component.html',
  styleUrls: ['./money-model-preview.component.css'],
})
export class MoneyModelPreviewComponent implements OnInit {
  @Input() symbolClasses: string =
    'text-sm font-semibold text-gray-600 me-1 align-self-start lh-1';
  @Input() valueClasses: string =
    'text-3xl font-semibold text-gray-900 me-1 lh-1 ls-n2';

  @Input() codeClasses: string =
    'text-sm font-semibold text-gray-600 me-1 align-self-end lh-base';
  @Input() valueFormat: string = '1.2-2';
  @Input() amount: Partial<MoneyModel>;
  constructor() {}

  ngOnInit() {}
}
