import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnimatedLoaderType } from '../../shared/partials/ai/ai-thinking-loader/ai-thinking-loader.component';
import { ProgressSpinnerService } from '../../shared/services/progress-spinner.service';

@Component({
  selector: 'app-application-loading',
  templateUrl: './app-application-loading.component.html',
  styleUrls: ['./app-application-loading.component.css'],
})
export class ApplicationLoadingComponent implements OnInit, OnDestroy {
  public animatedLoaderType = AnimatedLoaderType
  public message = 'Loading';
  public messageSubscription: Subscription;
  constructor(public spinService: ProgressSpinnerService) {}
  public ngOnDestroy(): void {
    // this.spinService.messageListner.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  public ngOnInit() {
    this.messageSubscription = this.spinService.messageListner.subscribe(
      (a) => {
        this.message = a;
      }
    );
  }
}
