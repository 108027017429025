// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.drop-active {
  color: var(--bs-warning-inverse);
    border-color: var(--bs-warning);
    background-color: var(--bs-warning);
}

.drop-active .svg-icon {
  color: var(--bs-warning-inverse);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRyYWZ0LWxhYmVsLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6Ijs7QUFFQTtFQUNFLGdDQUFnQztJQUM5QiwrQkFBK0I7SUFDL0IsbUNBQW1DO0FBQ3ZDOztBQUVBO0VBQ0UsZ0NBQWdDO0FBQ2xDIiwiZmlsZSI6ImRyYWZ0LWxhYmVsLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLmRyb3AtYWN0aXZlIHtcbiAgY29sb3I6IHZhcigtLWJzLXdhcm5pbmctaW52ZXJzZSk7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1icy13YXJuaW5nKTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1icy13YXJuaW5nKTtcbn1cblxuLmRyb3AtYWN0aXZlIC5zdmctaWNvbiB7XG4gIGNvbG9yOiB2YXIoLS1icy13YXJuaW5nLWludmVyc2UpO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/publish-status-badges/draft-label/draft-label.component.css"],"names":[],"mappings":";;AAEA;EACE,gCAAgC;IAC9B,+BAA+B;IAC/B,mCAAmC;AACvC;;AAEA;EACE,gCAAgC;AAClC;;AAEA,gmBAAgmB","sourcesContent":["\n\n.drop-active {\n  color: var(--bs-warning-inverse);\n    border-color: var(--bs-warning);\n    background-color: var(--bs-warning);\n}\n\n.drop-active .svg-icon {\n  color: var(--bs-warning-inverse);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
