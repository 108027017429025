// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.image-loaded-style {
  background-size: 100% !important;
  background-position: top center !important;
}

.loading-style {
  background-size: 37% !important;
  background-position: center center !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyZWF0ZS1wYWdlLXBvcHVwLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6Ijs7QUFFQTtFQUNFLHVCQUF1QjtBQUN6Qjs7QUFFQTtFQUNFLGdDQUFnQztFQUNoQywwQ0FBMEM7QUFDNUM7O0FBRUE7RUFDRSwrQkFBK0I7RUFDL0IsNkNBQTZDO0FBQy9DIiwiZmlsZSI6ImNyZWF0ZS1wYWdlLXBvcHVwLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLmlwLXN3aXRjaC1maXgge1xuICBtYXJnaW4tdG9wOjAgIWltcG9ydGFudDtcbn1cblxuLmltYWdlLWxvYWRlZC1zdHlsZSB7XG4gIGJhY2tncm91bmQtc2l6ZTogMTAwJSAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiB0b3AgY2VudGVyICFpbXBvcnRhbnQ7XG59XG5cbi5sb2FkaW5nLXN0eWxlIHtcbiAgYmFja2dyb3VuZC1zaXplOiAzNyUgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1wb3NpdGlvbjogY2VudGVyIGNlbnRlciAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-content/portal-pages/create-page-popup/create-page-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,0CAA0C;AAC5C;;AAEA;EACE,+BAA+B;EAC/B,6CAA6C;AAC/C;;AAEA,wuBAAwuB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.image-loaded-style {\n  background-size: 100% !important;\n  background-position: top center !important;\n}\n\n.loading-style {\n  background-size: 37% !important;\n  background-position: center center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
