// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symbol-group .symbol .symbol-label {
    transition: 0.2s all ease-in-out;
}

.symbol-group .symbol:hover .symbol-label {
    transform: scale(1.1);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXJzLXN5bWJvbHMtZ3JvdXAuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLGdDQUFnQztBQUNwQzs7QUFFQTtJQUNJLHFCQUFxQjtBQUN6QiIsImZpbGUiOiJ1c2Vycy1zeW1ib2xzLWdyb3VwLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc3ltYm9sLWdyb3VwIC5zeW1ib2wgLnN5bWJvbC1sYWJlbCB7XG4gICAgdHJhbnNpdGlvbjogMC4ycyBhbGwgZWFzZS1pbi1vdXQ7XG59XG5cbi5zeW1ib2wtZ3JvdXAgLnN5bWJvbDpob3ZlciAuc3ltYm9sLWxhYmVsIHtcbiAgICB0cmFuc2Zvcm06IHNjYWxlKDEuMSk7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/users-symbols-group/users-symbols-group.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB;AACA,4gBAA4gB","sourcesContent":[".symbol-group .symbol .symbol-label {\n    transition: 0.2s all ease-in-out;\n}\n\n.symbol-group .symbol:hover .symbol-label {\n    transform: scale(1.1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
