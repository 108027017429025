import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ApiService } from '../../../../services';
import { AppwriteException } from 'appwrite';
import * as PasswordRecoveryActions from '../password-forgot/password-forgot.actions';
import { AppwriteService } from 'src/app/shared/services/appwrite.sdk.service';

@Injectable()
export class PasswordForgotEffects {
  constructor(
    private actions$: Actions,
    private appwriteService: AppwriteService,

    private router: Router,
    private store: Store
  ) {}

  createPasswordRecoveryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordRecoveryActions.passwordRecoveryRequestActions.create),
      mergeMap(({ email }) => {
        return this.appwriteService.createPasswordRecoveryRequest(email)
          .pipe(
            map((response) => PasswordRecoveryActions.passwordRecoveryRequestActions.createSuccess({email})),
            catchError(async (error: any) => {
              
              return PasswordRecoveryActions.passwordRecoveryRequestActions.createSuccess({email});
            })
          );
      })
    )
  );


  passwordRecoveryConfirm$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PasswordRecoveryActions.passwordRecoveryConfirmActions.confirm),
    mergeMap(({ userId, secret, password }) => {
      return this.appwriteService.confirmPasswordRecoveryRequest({userId, secret, password})
        .pipe(
          map((response) => PasswordRecoveryActions.passwordRecoveryConfirmActions.confirmSuccess()),
          catchError(async (error: any) => {
            
            return PasswordRecoveryActions.passwordRecoveryConfirmActions.confirmFailure({error: error});
          })
        );
    })
  )
);
}
