// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-pages-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 1em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRlbXBsYXRlLWl0ZW0taW5mby1wb3B1cC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBYTtFQUNiLHFDQUFxQztFQUNyQyxvQkFBb0I7RUFDcEIsaUJBQWlCO0FBQ25CIiwiZmlsZSI6InRlbXBsYXRlLWl0ZW0taW5mby1wb3B1cC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRlbXBsYXRlLXBhZ2VzLWdyaWQge1xuICBkaXNwbGF5OiBncmlkO1xuICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCg0LCAxZnIpO1xuICBncmlkLWNvbHVtbi1nYXA6IDFlbTtcbiAgZ3JpZC1yb3ctZ2FwOiAxZW07XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/template-item/template-item-info-popup/template-item-info-popup.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA,4gBAA4gB","sourcesContent":[".template-pages-grid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-column-gap: 1em;\n  grid-row-gap: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
