// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}

.ip-table tbody td {
  padding: 0.4em 0.75em !important;
}

.ip-table th {
  padding: 1em 0.75em !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFmZmlsaWF0ZS1hbm5vdW5jZW1lbnQtaXRlbS1wb3B1cC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7O0FBRUE7RUFDRSx1QkFBdUI7QUFDekI7O0FBRUE7RUFDRSxvQ0FBb0M7QUFDdEM7O0FBRUE7RUFDRSxnQ0FBZ0M7QUFDbEM7O0FBRUE7RUFDRSw4QkFBOEI7QUFDaEMiLCJmaWxlIjoiYWZmaWxpYXRlLWFubm91bmNlbWVudC1pdGVtLXBvcHVwLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLmlwLXN3aXRjaC1maXgge1xuICBtYXJnaW4tdG9wOjAgIWltcG9ydGFudDtcbn1cblxuLnN3aXRjaCBpbnB1dDpkaXNhYmxlZCB+IHNwYW46YmVmb3JlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2RhZGFkYSAhaW1wb3J0YW50O1xufVxuXG4uaXAtdGFibGUgdGJvZHkgdGQge1xuICBwYWRkaW5nOiAwLjRlbSAwLjc1ZW0gIWltcG9ydGFudDtcbn1cblxuLmlwLXRhYmxlIHRoIHtcbiAgcGFkZGluZzogMWVtIDAuNzVlbSAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/affiliate-dashboard/my-dashboard/affiliate-announcements/affiliate-announcement-item-popup/affiliate-announcement-item-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;AAEA,4vBAA4vB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n.ip-table tbody td {\n  padding: 0.4em 0.75em !important;\n}\n\n.ip-table th {\n  padding: 1em 0.75em !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
