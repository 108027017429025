// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}

.ip-table tbody td {
  padding: 0.4em 0.75em !important;
}

.ip-table th {
  padding: 1em 0.75em !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNhbGVzLWl0ZW0tcG9wdXAuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0Usb0NBQW9DO0FBQ3RDOztBQUVBO0VBQ0UsZ0NBQWdDO0FBQ2xDOztBQUVBO0VBQ0UsOEJBQThCO0FBQ2hDIiwiZmlsZSI6InNhbGVzLWl0ZW0tcG9wdXAuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuXG4uaXAtc3dpdGNoLWZpeCB7XG4gIG1hcmdpbi10b3A6MCAhaW1wb3J0YW50O1xufVxuXG4uc3dpdGNoIGlucHV0OmRpc2FibGVkIH4gc3BhbjpiZWZvcmUge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZGFkYWRhICFpbXBvcnRhbnQ7XG59XG5cbi5pcC10YWJsZSB0Ym9keSB0ZCB7XG4gIHBhZGRpbmc6IDAuNGVtIDAuNzVlbSAhaW1wb3J0YW50O1xufVxuXG4uaXAtdGFibGUgdGgge1xuICBwYWRkaW5nOiAxZW0gMC43NWVtICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/owner-earnings/sales/sales-item/sales-item-popup/sales-item-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;AAEA,gtBAAgtB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n.ip-table tbody td {\n  padding: 0.4em 0.75em !important;\n}\n\n.ip-table th {\n  padding: 1em 0.75em !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
