// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  transition: all 0.35s ease-in-out !important;
}

.more-info {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out !important;
}
.item:hover .more-info {
  opacity: 1;
  transform: translateY(0px);
  max-height: 50px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZ1bm5lbC1saXN0LWl0ZW0uY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDRDQUE0QztBQUM5Qzs7QUFFQTtFQUNFLGFBQWE7RUFDYixnQkFBZ0I7RUFDaEIsVUFBVTtFQUNWLDRCQUE0QjtFQUM1QiwyQ0FBMkM7QUFDN0M7QUFDQTtFQUNFLFVBQVU7RUFDViwwQkFBMEI7RUFDMUIsZ0JBQWdCO0FBQ2xCIiwiZmlsZSI6ImZ1bm5lbC1saXN0LWl0ZW0uY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pdGVtIHtcbiAgdHJhbnNpdGlvbjogYWxsIDAuMzVzIGVhc2UtaW4tb3V0ICFpbXBvcnRhbnQ7XG59XG5cbi5tb3JlLWluZm8ge1xuICBtYXgtaGVpZ2h0OiAwO1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICBvcGFjaXR5OiAwO1xuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoLTEwcHgpO1xuICB0cmFuc2l0aW9uOiBhbGwgMC41cyBlYXNlLWluLW91dCAhaW1wb3J0YW50O1xufVxuLml0ZW06aG92ZXIgLm1vcmUtaW5mbyB7XG4gIG9wYWNpdHk6IDE7XG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlWSgwcHgpO1xuICBtYXgtaGVpZ2h0OiA1MHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/funnels-list/funnel-list-item/funnel-list-item.component.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,UAAU;EACV,4BAA4B;EAC5B,2CAA2C;AAC7C;AACA;EACE,UAAU;EACV,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA,40BAA40B","sourcesContent":[".item {\n  transition: all 0.35s ease-in-out !important;\n}\n\n.more-info {\n  max-height: 0;\n  overflow: hidden;\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: all 0.5s ease-in-out !important;\n}\n.item:hover .more-info {\n  opacity: 1;\n  transform: translateY(0px);\n  max-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
