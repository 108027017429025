// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-floating > .form-control:focus ~ label {
    /* color: var(--bs-primary) !important; */
}
.form-floating > .form-control:not(:placeholder-shown) ~ label{
    /* color: var(--bs-dark) !important; */
}


/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {
    color: var(--bs-danger) !important;
} */

/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{
    color: var(--bs-primary) !important;
} */
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItcmVnaXN0ZXItZm9ybS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0kseUNBQXlDO0FBQzdDO0FBQ0E7SUFDSSxzQ0FBc0M7QUFDMUM7OztBQUdBOztHQUVHOztBQUVIOztHQUVHIiwiZmlsZSI6InVzZXItcmVnaXN0ZXItZm9ybS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0tZmxvYXRpbmcgPiAuZm9ybS1jb250cm9sOmZvY3VzIH4gbGFiZWwge1xuICAgIC8qIGNvbG9yOiB2YXIoLS1icy1wcmltYXJ5KSAhaW1wb3J0YW50OyAqL1xufVxuLmZvcm0tZmxvYXRpbmcgPiAuZm9ybS1jb250cm9sOm5vdCg6cGxhY2Vob2xkZXItc2hvd24pIH4gbGFiZWx7XG4gICAgLyogY29sb3I6IHZhcigtLWJzLWRhcmspICFpbXBvcnRhbnQ7ICovXG59XG5cblxuLyogLmZvcm0tZmxvYXRpbmcgPiAuZm9ybS1jb250cm9sOm5vdCg6Zm9jdXMpLm5nLWludmFsaWQubmctdG91Y2hlZCB+IGxhYmVsIHtcbiAgICBjb2xvcjogdmFyKC0tYnMtZGFuZ2VyKSAhaW1wb3J0YW50O1xufSAqL1xuXG4vKiAuZm9ybS1mbG9hdGluZyA+IC5mb3JtLWNvbnRyb2w6Zm9jdXMgfiBsYWJlbCwgLmZvcm0tZmxvYXRpbmcgPiAuZm9ybS1jb250cm9sOm5vdCg6cGxhY2Vob2xkZXItc2hvd24pIH4gbGFiZWx7XG4gICAgY29sb3I6IHZhcigtLWJzLXByaW1hcnkpICFpbXBvcnRhbnQ7XG59ICovIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/register/user-register-form/user-register-form.component.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;AACA;IACI,sCAAsC;AAC1C;;;AAGA;;GAEG;;AAEH;;GAEG;AACH,w/BAAw/B","sourcesContent":[".form-floating > .form-control:focus ~ label {\n    /* color: var(--bs-primary) !important; */\n}\n.form-floating > .form-control:not(:placeholder-shown) ~ label{\n    /* color: var(--bs-dark) !important; */\n}\n\n\n/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {\n    color: var(--bs-danger) !important;\n} */\n\n/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{\n    color: var(--bs-primary) !important;\n} */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
