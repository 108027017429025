import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services';

export const errorInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<any> => {
  const authenticationService = inject(AuthenticationService);

  if (request.headers.has('disableInterceptor')) {
    return next(request);
  }

  return next(request).pipe(
    catchError((err) => handleHttpError(err, request, next, authenticationService))
  );
};

const handleHttpError = (
  err: any,
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authenticationService: AuthenticationService
): Observable<any> => {
  if (err && typeof err !== 'string' && 'status' in err) {
    if (err.status === HttpStatusCode.Unauthorized) {
      return handleUnauthorizedError(request, authenticationService);
    }
    return throwError(
      () =>
        err.error
          ? err.error
          : 'Internal error happened during requesting resource.'
    );
  }

  const error =
    typeof err === 'string'
      ? err
      : err instanceof HttpErrorResponse
      ? `${err.status} ${err.statusText}`
      : 'error' in err
      ? err.error.message
      : 'statusText' in err
      ? err.statusText
      : `${err}`;

  return throwError(() => error);
};

const handleUnauthorizedError = (
  request: HttpRequest<any>,
  authenticationService: AuthenticationService
): Observable<any> => {
  authenticationService.logout();
  return throwError(() => 'Unauthorized');
};
