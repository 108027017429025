import { HttpErrorResponse } from "@angular/common/http";

export type colorSet = | 'primary' | 'info' | 'success' | 'warning' | 'light' | string;



export class CustomCheckBoxCardOption{
    label: string;
    value: any;
    description: string;
    colorSet: colorSet;
    iconName?: string;
    svgURL? : string;
    soon?: boolean;
  }

  export type SystemProcessLoadingStatus = ('none' | 'loading' | 'updating' | 'done' | 'error' | 'deleting' | 'creating') | string;

  export class SystemTypedError {
    processType: SystemProcessLoadingStatus;
    error: any
  }

  export class SystemProcess {
    constructor(status: SystemProcessLoadingStatus = 'none', error: string = '') {
      Object.assign(this, { status, error });
    }
    status: SystemProcessLoadingStatus;
    error: string;
    errorBody?: any;
  }
  