import { SharedProfileService } from './../../../components/profile/shared-profile.service';
import {
  BreadcrumbsElement,
  NavigationMenuItem,
  TitleBreadcrumbsConfig,
} from './../../../models/owner-dashboard';
import { NavigationEnd, Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PortalSocketService } from '../../services/portal-socket.service';
import { Store } from '@ngrx/store';
import { setWebsiteAsReady } from '../../store/_features/website/website.actions';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../services';
import { selectIsWebsiteReady } from '../../store/_features/website/website.selector';
import { Subscription, filter, skipWhile, take } from 'rxjs';
import { WebsiteModel } from 'src/app/components/user-dashboard/portal/portal.model';

export enum BackSettingsType {
  standard = 'standard',
  one_time = 'one_time',
}

export class backSettingsObject {
  route: string;
  text: string;
  refUrl?: string;
  type: BackSettingsType;
  temp?: backSettingsObject;
}
@Injectable({
  providedIn: 'root',
})
export class SharedOwnerDashboardService {
  isGuideVisible: boolean = false;
  isGuideOpen: boolean = false;
  breadcrumbsBase: any = {
    dashboard: [{ label: 'Dashboard', url: '/' }],
    product: [
      { label: 'Dashboard', url: '/' },
      { label: 'Products', url: 'product' },
    ],
    contact: [
      { label: 'Dashboard', url: '/' },
      { label: 'Contacts', url: 'contact' },
    ],
    offer: [
      { label: 'Dashboard', url: '/' },
      { label: 'Offers', url: 'offer' },
    ],
    funnel: [
      { label: 'Dashboard', url: '/' },
      { label: 'Funnels', url: 'funnel' },
    ],
    website: [
      { label: 'Dashboard', url: '/' },
      { label: 'Website', url: 'website' },
    ],
    earnings: [
      { label: 'Dashboard', url: '/' },
      { label: 'Sales & Earnings', url: 'earnings' },
    ],
    refund: [
      { label: 'Dashboard', url: '/' },
      { label: 'Refund Requests', url: 'refund' },
    ],
  };

  titleBreadcrumbConfig: TitleBreadcrumbsConfig = {
    title: { main: 'Welcome back', sub: 'You are logged in as a Learmo Owner' },
    breadcrumb: [],
    actionsSelectors: [],
  };

  isSubmenuItemActive: boolean = false;
  sideMenu: NavigationMenuItem[] = [
    {
      tagId: 'dashboardTab',
      tooltip: { placement: 'right', text: 'Dashboard' },
      label: 'Dashboard',
      routerLink: '/dashboard',
      iconName: 'dashboard',
      routerLinkExact: true,
    },
    {
      tagId: 'productsTab',
      tooltip: { placement: 'right', text: 'Products' },
      label: 'Products',
      routerLink: 'product',
      iconName: 'products',
      routerLinkExact: false,
    },
    {
      tagId: 'salesTab',
      tooltip: { placement: 'right', text: 'Sales' },
      label: 'Sales',
      expandable: {
        active: true,
        status: false,
      },
      routerLink: null,
      iconName: 'sales',

      subItems: [
        // {
        //   tagId: 'globalOffersTab',
        //   label: 'Offers',
        //   routerLink: 'offer',
        // },
        {
          tagId: 'earningsTab',
          label: 'Sales & Earnings',
          routerLink: 'earnings',
        },
        {
          tagId: 'refundTab',
          label: 'Refund',
          routerLink: 'refund',
        },
      ],
      routerLinkExact: false,
    },

    {
      label: 'General',
      separator: true
    },
    {
      tagId: 'websiteTab',
      tooltip: { placement: 'right', text: 'Website' },
      label: 'Website',
      routerLink: '/dashboard/website',
      iconName: 'website',
      expandable: { active: true, status: false },
      subItems: [
        {
          tagId: 'overviewTab',
          label: 'Overview',
          routerLink: '/dashboard/website/overview',
        },
        {
          tagId: 'identityTab',
          label: 'Site Details',
          routerLink: '/dashboard/website/identity',
        },
        {
          tagId: 'designTab',
          label: 'Design & Branding',
          routerLink: '/dashboard/website/branding',
        },
        {
          tagId: 'contentTab',
          label: 'Pages',
          routerLink: '/dashboard/website/content',
        },
        {
          tagId: 'blogTab',
          label: 'Blog',
          routerLink: '/dashboard/website/blog',
        },
        {
          tagId: 'settingsTab',
          label: 'Settings',
          routerLink: '/dashboard/website/settings',
        },
      ],
      routerLinkExact: false,
    },
    {
      tagId: 'marketingTab',
      tooltip: { placement: 'right', text: 'Marketing' },
      label: 'Marketing',
      expandable: {
        active: true,
        status: false,
      },
      routerLink: null,
      iconName: 'marketing',

      subItems: [

        {
          tagId: 'funnelsTab',
          label: 'Funnels',
          routerLink: 'funnel',
        },
      ],
      routerLinkExact: false,
    },
    {
      tagId: 'contactsTab',
      tooltip: { placement: 'right', text: 'Contacts' },
      label: 'Contacts',
      routerLink: 'contact',
      iconName: 'contact',
      routerLinkExact: true,
    },

    {
      label: 'Reports',
      separator: true
    },
    {
      tagId: 'reportsTab',
      tooltip: { placement: 'right', text: 'Reports & Analytics' },
      label: 'Reports & Analytics',
      routerLink: 'reports',
      iconName: 'analytics',
      routerLinkExact: true,
    },


    // {
    //   tagId: 'settingsTab',
    //   tooltip: { placement: 'right', text: 'Settings' },
    //   label: 'Settings',
    //   routerLink: 'website/settings',
    //   iconName: 'settings',
    //   routerLinkExact: true,
    // },
  ];

  backSettings: backSettingsObject = {
    route: '/dashboard',
    text: 'Dashboard',
    type: BackSettingsType.standard,
  };

  canNavigateAll = true;

  websiteIsReadySound: HTMLAudioElement = new Audio('assets/notification.mp3');
  isWebsiteReady: boolean;

  onBoardingSubscriptions: Subscription[];
  constructor(
    public router: Router,
    private title: Title,
    private socketService: PortalSocketService,
    private store: Store,
    private toastr: ToastrService,
    private sharedProfileService: SharedProfileService,
    private authenticationService: AuthenticationService
  ) {
    this.store
      .select(selectIsWebsiteReady)
      .pipe(filter((value) => value !== null))
      .subscribe((value) => {
        this.isWebsiteReady = value;
        if (
          !this.sharedProfileService.currentUser.onBoarding.websiteSetting &&
          !!value
        ) {
          this.listenToOnboarding();
        }
      });

    this.startOwnerWebsiteProcessingListening();
    this.socketService.onSocketSetupDone.subscribe((value) => {
      this.startOwnerWebsiteProcessingListening();
    });


    const initialUrl = this.router.url;
    
    this.handleRouteChange(initialUrl);
  
    // Subscribe to router events to handle changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url;
        this.handleRouteChange(currentUrl);
      }
    });
  }

  handleRouteChange = (url: string) => {
    
    // Check the URL and update isGuideVisible accordingly
    if (url === '/dashboard') {
      this.isGuideVisible = false;
    } else {
      this.isGuideVisible = true;
    }
  }

  startOwnerWebsiteProcessingListening() {
    this.socketService.websiteListener
      .pipe(take(1))
      .subscribe((website: WebsiteModel) => {
        this.store.dispatch(setWebsiteAsReady({website}));
        if (!!this.sharedProfileService.currentUser.onBoarding.websiteSetting) {
          this.triggetWebsiteIsReadyAction();
        } else {
          this.listenToOnboarding();
        }
      });
  }

  listenToOnboarding() {
    this.authenticationService.currentUser
      .pipe(
        skipWhile((currentUser) => !currentUser?.onBoarding?.websiteSetting),
        take(1)
      )
      .subscribe((currentUser) => {
        if (!!currentUser?.onBoarding?.websiteSetting) {
          this.triggetWebsiteIsReadyAction();
        }
      });
  }

  triggetWebsiteIsReadyAction() {
    this.playWebsiteReadySound();
    Swal.fire({
      icon: 'success',
      title: 'Your Website is ready!',
      html: 'Would you be interested in taking a tour of the Learmo platform?',
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light',
      },
      showCancelButton: true,
      confirmButtonText: 'Discover Learmo',
      cancelButtonText: 'Skip',
    }).then((result) => {
      if (result.isConfirmed) {
        this.toastr.success('Tour is being implemented');
      } else {
        this.toastr.warning('You can take a tour anytime!');
      }
    });
  }

  playWebsiteReadySound() {
    this.websiteIsReadySound.play();
  }

  expandSidebarItem(sideMenu: NavigationMenuItem[], item: NavigationMenuItem) {
    this.collapseSidebarItems(sideMenu);
    if (item.expandable?.active) {
      item.expandable.status = true;
        setTimeout(() => {
          this.scrollTo(item.tagId, 'start');
        }, 350);
      
    } 
  }

  collapseSidebarItem(item: NavigationMenuItem) {
    item.expandable.status = false;
  }

  collapseSidebarItems(sideMenu: NavigationMenuItem[]) {
    sideMenu.forEach((menuItem) => {
      if (menuItem?.expandable?.status) {
        menuItem.expandable.status = false;
      }
    });
  }

  scrollTo(elementID: string, position: ScrollLogicalPosition = 'nearest') {
    (document.getElementById(elementID) as HTMLElement)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  // TitleBreadCrumb Controller
  setWelcomeBackMessage(title: {
    main?: string;
    firstName: string;
    role?: string;
  }) {
    let fullMessage = `${
      !!title?.main ? title.main : '<span class="font-light">Welcome back</span>'
    }, ${title.firstName}`;
    this.setTitle({
      main: fullMessage,
      sub: `You are logged in as ${title.role}`,
    });
    this.clearBreadcrumbs();
    this.title.setTitle('Learmo Platform > Dashboard');
  }

  clearBreadcrumbs() {
    this.setBreadcrumbs({ customBreadCrumbs: [] });
  }
  setTitle(title: { main: string; sub?: string }, actions: any[] = []) {
    if (title?.main != null) {
      this.titleBreadcrumbConfig.title.main = title.main;
      this.title.setTitle(title.main);
    }

    if (title?.sub != null) {
      this.titleBreadcrumbConfig.title.sub = title.sub;
    }

    this.titleBreadcrumbConfig.actionsSelectors = actions;
  }

  clearActions() {
    this.setTitle(null, []);
  }

  clearTitle() {
    this.setTitle({ sub: '', main: '' });
  }

  renameLastNodeIfExists(label: string) {
    if (this.titleBreadcrumbConfig?.breadcrumb?.length) {
      this.titleBreadcrumbConfig.breadcrumb[
        this.titleBreadcrumbConfig.breadcrumb.length - 1
      ].label = label;
    }
  }
  setBreadcrumbs(
    config: {
      base?: 'offer' | 'product' | 'website' | 'funnel' | 'earnings' | 'refund' | 'contact';
      lastNode?: string;
      customBreadCrumbs?: BreadcrumbsElement[];
    } = { customBreadCrumbs: [] }
  ) {
    if (!config?.customBreadCrumbs?.length && !!config.base) {
      let baseClone = JSON.parse(
        JSON.stringify(this.breadcrumbsBase[config.base])
      ) as Array<BreadcrumbsElement>;

      if (baseClone?.length) {
        if (!!config.lastNode) {
          baseClone.push({
            label: config.lastNode,
            url: null,
            isLastNode: true,
          });
          this.title.setTitle(config.lastNode);
        } else {
          baseClone[baseClone.length - 1].url = null;
          this.title.setTitle(baseClone[baseClone.length - 1].label);
        }

        this.titleBreadcrumbConfig.breadcrumb = baseClone;
      } else {
        this.titleBreadcrumbConfig.breadcrumb = [];
      }
    } else {
      this.titleBreadcrumbConfig.breadcrumb = config.customBreadCrumbs;
    }
  }
  // TitleBreadCrumb Controller
  goBack() {
    this.router.navigateByUrl(this.backSettings.route);
    this.checkBackSettings();
  }

  setBackRoute(
    text: string,
    route: string = this.router.url,
    refUrl: string = undefined,
    type: BackSettingsType = BackSettingsType.standard
  ) {
    let currentSettings = this.getBackRoute;
    let newSettings: backSettingsObject = { route, text, refUrl, type };
    setTimeout(() => {
      if (type == BackSettingsType.one_time) {
        newSettings.temp = currentSettings;
      } else {
        newSettings.temp = undefined;
      }
      localStorage.setItem('bcfg', JSON.stringify(newSettings));
      this.backSettings = this.getBackRoute;
    }, 350);
  }

  checkBackSettings() {
    let backSettings = this.getBackRoute;
    let temp = backSettings?.temp;

    if (backSettings?.type == BackSettingsType.one_time) {
      this.setBackRoute(temp.text, temp.route, temp.refUrl, temp.type);
    }
  }

  setRefUrl(url: string) {
    let backCfg = this.getBackRoute;
    backCfg.refUrl = url;

    localStorage.setItem('bcfg', JSON.stringify(backCfg));
    this.backSettings = this.getBackRoute;
  }

  destroyBackRoute() {
    localStorage.removeItem('bcfg');
  }

  get getBackRoute(): backSettingsObject {
    const settings = JSON.parse(localStorage.getItem('bcfg'));
    return settings;
  }

  enableNavigate() {
    this.canNavigateAll = true;
  }

  disableNavigate() {
    this.canNavigateAll = false;
  }
}
