// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom button styles */
.custom-quill-editor .custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Adjust the padding as needed */
  cursor: pointer;
  color: #555; /* Adjust the text color as needed */
}

.learmo-editor-modal .modal-content {
  padding: 2em
}

.codex-editor__redactor {
  padding-bottom:100px !important ;
}

.cdx-list__item {
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxlYXJtby1ibG9jay1lZGl0b3IuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSx5QkFBeUI7QUFDekI7RUFDRSxhQUFhO0VBQ2IsbUJBQW1CO0VBQ25CLHVCQUF1QjtFQUN2QixlQUFlLEVBQUUsaUNBQWlDO0VBQ2xELGVBQWU7RUFDZixXQUFXLEVBQUUsb0NBQW9DO0FBQ25EOztBQUVBO0VBQ0U7QUFDRjs7QUFFQTtFQUNFLGdDQUFnQztBQUNsQzs7QUFFQTtFQUNFLHFCQUFxQjtBQUN2QiIsImZpbGUiOiJsZWFybW8tYmxvY2stZWRpdG9yLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiBDdXN0b20gYnV0dG9uIHN0eWxlcyAqL1xuLmN1c3RvbS1xdWlsbC1lZGl0b3IgLmN1c3RvbS1idXR0b24ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgcGFkZGluZzogMCAxMHB4OyAvKiBBZGp1c3QgdGhlIHBhZGRpbmcgYXMgbmVlZGVkICovXG4gIGN1cnNvcjogcG9pbnRlcjtcbiAgY29sb3I6ICM1NTU7IC8qIEFkanVzdCB0aGUgdGV4dCBjb2xvciBhcyBuZWVkZWQgKi9cbn1cblxuLmxlYXJtby1lZGl0b3ItbW9kYWwgLm1vZGFsLWNvbnRlbnQge1xuICBwYWRkaW5nOiAyZW1cbn1cblxuLmNvZGV4LWVkaXRvcl9fcmVkYWN0b3Ige1xuICBwYWRkaW5nLWJvdHRvbToxMDBweCAhaW1wb3J0YW50IDtcbn1cblxuLmNkeC1saXN0X19pdGVtIHtcbiAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/learmo-block-editor/learmo-block-editor.component.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe,EAAE,iCAAiC;EAClD,eAAe;EACf,WAAW,EAAE,oCAAoC;AACnD;;AAEA;EACE;AACF;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB;AACA,gkCAAgkC","sourcesContent":["/* Custom button styles */\n.custom-quill-editor .custom-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0 10px; /* Adjust the padding as needed */\n  cursor: pointer;\n  color: #555; /* Adjust the text color as needed */\n}\n\n.learmo-editor-modal .modal-content {\n  padding: 2em\n}\n\n.codex-editor__redactor {\n  padding-bottom:100px !important ;\n}\n\n.cdx-list__item {\n  padding: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
