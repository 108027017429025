// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-option-item.soon {
    opacity: 0.5;
    pointer-events: none;
}


.payment-option-item.active {
    border: none !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtY2hlY2tib3gtY2FyZC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksWUFBWTtJQUNaLG9CQUFvQjtBQUN4Qjs7O0FBR0E7SUFDSSx1QkFBdUI7QUFDM0IiLCJmaWxlIjoiZHluYW1pYy1jaGVja2JveC1jYXJkLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGF5bWVudC1vcHRpb24taXRlbS5zb29uIHtcbiAgICBvcGFjaXR5OiAwLjU7XG4gICAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG59XG5cblxuLnBheW1lbnQtb3B0aW9uLWl0ZW0uYWN0aXZlIHtcbiAgICBib3JkZXI6IG5vbmUgIWltcG9ydGFudDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/dynamic-checkbox-card/dynamic-checkbox-card.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;AACxB;;;AAGA;IACI,uBAAuB;AAC3B;AACA,ghBAAghB","sourcesContent":[".payment-option-item.soon {\n    opacity: 0.5;\n    pointer-events: none;\n}\n\n\n.payment-option-item.active {\n    border: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
