// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btcpay-card {
  height: fit-content;
  min-height: 150px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.btcpay-card.question {
  height: 240px;
  min-height: 240px;
}

.btcpay-card.registered {
  min-height: 80px;
  height: 50px;
}

.custom-icon-switch.switch.switch-lg input:empty ~ span:after {
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ0Y3BheS1zcGVjaWFsLW1ldGhvZC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsbUJBQW1CO0VBQ25CLGlCQUFpQjtFQUNqQiwwREFBMEQ7QUFDNUQ7O0FBRUE7RUFDRSxhQUFhO0VBQ2IsaUJBQWlCO0FBQ25COztBQUVBO0VBQ0UsZ0JBQWdCO0VBQ2hCLFlBQVk7QUFDZDs7QUFFQTtBQUNBIiwiZmlsZSI6ImJ0Y3BheS1zcGVjaWFsLW1ldGhvZC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmJ0Y3BheS1jYXJkIHtcbiAgaGVpZ2h0OiBmaXQtY29udGVudDtcbiAgbWluLWhlaWdodDogMTUwcHg7XG4gIHRyYW5zaXRpb246IGFsbCAwLjVzIGN1YmljLWJlemllcigwLjY4LCAtMC41NSwgMC4yNywgMS41NSk7XG59XG5cbi5idGNwYXktY2FyZC5xdWVzdGlvbiB7XG4gIGhlaWdodDogMjQwcHg7XG4gIG1pbi1oZWlnaHQ6IDI0MHB4O1xufVxuXG4uYnRjcGF5LWNhcmQucmVnaXN0ZXJlZCB7XG4gIG1pbi1oZWlnaHQ6IDgwcHg7XG4gIGhlaWdodDogNTBweDtcbn1cblxuLmN1c3RvbS1pY29uLXN3aXRjaC5zd2l0Y2guc3dpdGNoLWxnIGlucHV0OmVtcHR5IH4gc3BhbjphZnRlciB7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay-special-method/btcpay-special-method.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,0DAA0D;AAC5D;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;AACA;;AAEA,g3BAAg3B","sourcesContent":[".btcpay-card {\n  height: fit-content;\n  min-height: 150px;\n  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);\n}\n\n.btcpay-card.question {\n  height: 240px;\n  min-height: 240px;\n}\n\n.btcpay-card.registered {\n  min-height: 80px;\n  height: 50px;\n}\n\n.custom-icon-switch.switch.switch-lg input:empty ~ span:after {\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
