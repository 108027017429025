// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  h1.splash-message {
    animation: fadeInOut 2s ease-in-out forwards;
  }
  

  .bullet {
    transition: all 0.5s ease-in-out;
  }
  .bullet.active {
    width: 30px !important;
    background-color: var(--bs-primary) !important;
  }

  .bullet:hover {
    background-color: var(--bs-primary) !important;
  }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndlbGNvbWUtc3RlcC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksS0FBSyxVQUFVLEVBQUU7SUFDakIsTUFBTSxVQUFVLEVBQUU7SUFDbEIsT0FBTyxVQUFVLEVBQUU7RUFDckI7O0VBRUE7SUFDRSw0Q0FBNEM7RUFDOUM7OztFQUdBO0lBQ0UsZ0NBQWdDO0VBQ2xDO0VBQ0E7SUFDRSxzQkFBc0I7SUFDdEIsOENBQThDO0VBQ2hEOztFQUVBO0lBQ0UsOENBQThDO0VBQ2hEIiwiZmlsZSI6IndlbGNvbWUtc3RlcC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGtleWZyYW1lcyBmYWRlSW5PdXQge1xuICAgIDAlIHsgb3BhY2l0eTogMDsgfVxuICAgIDUwJSB7IG9wYWNpdHk6IDE7IH1cbiAgICAxMDAlIHsgb3BhY2l0eTogMDsgfVxuICB9XG4gIFxuICBoMS5zcGxhc2gtbWVzc2FnZSB7XG4gICAgYW5pbWF0aW9uOiBmYWRlSW5PdXQgMnMgZWFzZS1pbi1vdXQgZm9yd2FyZHM7XG4gIH1cbiAgXG5cbiAgLmJ1bGxldCB7XG4gICAgdHJhbnNpdGlvbjogYWxsIDAuNXMgZWFzZS1pbi1vdXQ7XG4gIH1cbiAgLmJ1bGxldC5hY3RpdmUge1xuICAgIHdpZHRoOiAzMHB4ICFpbXBvcnRhbnQ7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYnMtcHJpbWFyeSkgIWltcG9ydGFudDtcbiAgfVxuXG4gIC5idWxsZXQ6aG92ZXIge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLXByaW1hcnkpICFpbXBvcnRhbnQ7XG4gIH0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/account-setup-wizard/welcome-step/welcome-step.component.css"],"names":[],"mappings":"AAAA;IACI,KAAK,UAAU,EAAE;IACjB,MAAM,UAAU,EAAE;IAClB,OAAO,UAAU,EAAE;EACrB;;EAEA;IACE,4CAA4C;EAC9C;;;EAGA;IACE,gCAAgC;EAClC;EACA;IACE,sBAAsB;IACtB,8CAA8C;EAChD;;EAEA;IACE,8CAA8C;EAChD;AACF,giCAAgiC","sourcesContent":["@keyframes fadeInOut {\n    0% { opacity: 0; }\n    50% { opacity: 1; }\n    100% { opacity: 0; }\n  }\n  \n  h1.splash-message {\n    animation: fadeInOut 2s ease-in-out forwards;\n  }\n  \n\n  .bullet {\n    transition: all 0.5s ease-in-out;\n  }\n  .bullet.active {\n    width: 30px !important;\n    background-color: var(--bs-primary) !important;\n  }\n\n  .bullet:hover {\n    background-color: var(--bs-primary) !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
