// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .user-drop-button, .user-drop-button img.symbol {
  border-radius: 15px;
} */
.user-drop-button {
  /* background-color: var(--bs-primary) !important; */
  /* padding: 1px; */
}

.user-drop-button[aria-expanded="true"],
.user-drop-button[aria-expanded="false"]:hover {
  /* background-color: var(--bs-primary-light) !important; */
}

/* button[aria-expanded="false"]:hover,
button[aria-expanded="true"] {
  background-color: #ebebeb !important;
} */

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItZHJvcC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOztHQUVHO0FBQ0g7RUFDRSxvREFBb0Q7RUFDcEQsa0JBQWtCO0FBQ3BCOztBQUVBOztFQUVFLDBEQUEwRDtBQUM1RDs7QUFFQTs7O0dBR0ciLCJmaWxlIjoidXNlci1kcm9wLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiAudXNlci1kcm9wLWJ1dHRvbiwgLnVzZXItZHJvcC1idXR0b24gaW1nLnN5bWJvbCB7XG4gIGJvcmRlci1yYWRpdXM6IDE1cHg7XG59ICovXG4udXNlci1kcm9wLWJ1dHRvbiB7XG4gIC8qIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLXByaW1hcnkpICFpbXBvcnRhbnQ7ICovXG4gIC8qIHBhZGRpbmc6IDFweDsgKi9cbn1cblxuLnVzZXItZHJvcC1idXR0b25bYXJpYS1leHBhbmRlZD1cInRydWVcIl0sXG4udXNlci1kcm9wLWJ1dHRvblthcmlhLWV4cGFuZGVkPVwiZmFsc2VcIl06aG92ZXIge1xuICAvKiBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1icy1wcmltYXJ5LWxpZ2h0KSAhaW1wb3J0YW50OyAqL1xufVxuXG4vKiBidXR0b25bYXJpYS1leHBhbmRlZD1cImZhbHNlXCJdOmhvdmVyLFxuYnV0dG9uW2FyaWEtZXhwYW5kZWQ9XCJ0cnVlXCJdIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ViZWJlYiAhaW1wb3J0YW50O1xufSAqL1xuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/user-drop/user-drop.component.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,oDAAoD;EACpD,kBAAkB;AACpB;;AAEA;;EAEE,0DAA0D;AAC5D;;AAEA;;;GAGG;;AAEH,g9BAAg9B","sourcesContent":["/* .user-drop-button, .user-drop-button img.symbol {\n  border-radius: 15px;\n} */\n.user-drop-button {\n  /* background-color: var(--bs-primary) !important; */\n  /* padding: 1px; */\n}\n\n.user-drop-button[aria-expanded=\"true\"],\n.user-drop-button[aria-expanded=\"false\"]:hover {\n  /* background-color: var(--bs-primary-light) !important; */\n}\n\n/* button[aria-expanded=\"false\"]:hover,\nbutton[aria-expanded=\"true\"] {\n  background-color: #ebebeb !important;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
