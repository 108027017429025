// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-floating > .form-control:focus ~ label {
    /* color: var(--bs-primary) !important; */
}
.form-floating > .form-control:not(:placeholder-shown) ~ label{
    /* color: var(--bs-dark) !important; */
}


/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {
    color: var(--bs-danger) !important;
} */

/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{
    color: var(--bs-primary) !important;
} */
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndhaXQtbGlzdC1mb3JtLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSx5Q0FBeUM7QUFDN0M7QUFDQTtJQUNJLHNDQUFzQztBQUMxQzs7O0FBR0E7O0dBRUc7O0FBRUg7O0dBRUciLCJmaWxlIjoid2FpdC1saXN0LWZvcm0uY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5mb3JtLWZsb2F0aW5nID4gLmZvcm0tY29udHJvbDpmb2N1cyB+IGxhYmVsIHtcbiAgICAvKiBjb2xvcjogdmFyKC0tYnMtcHJpbWFyeSkgIWltcG9ydGFudDsgKi9cbn1cbi5mb3JtLWZsb2F0aW5nID4gLmZvcm0tY29udHJvbDpub3QoOnBsYWNlaG9sZGVyLXNob3duKSB+IGxhYmVse1xuICAgIC8qIGNvbG9yOiB2YXIoLS1icy1kYXJrKSAhaW1wb3J0YW50OyAqL1xufVxuXG5cbi8qIC5mb3JtLWZsb2F0aW5nID4gLmZvcm0tY29udHJvbDpub3QoOmZvY3VzKS5uZy1pbnZhbGlkLm5nLXRvdWNoZWQgfiBsYWJlbCB7XG4gICAgY29sb3I6IHZhcigtLWJzLWRhbmdlcikgIWltcG9ydGFudDtcbn0gKi9cblxuLyogLmZvcm0tZmxvYXRpbmcgPiAuZm9ybS1jb250cm9sOmZvY3VzIH4gbGFiZWwsIC5mb3JtLWZsb2F0aW5nID4gLmZvcm0tY29udHJvbDpub3QoOnBsYWNlaG9sZGVyLXNob3duKSB+IGxhYmVse1xuICAgIGNvbG9yOiB2YXIoLS1icy1wcmltYXJ5KSAhaW1wb3J0YW50O1xufSAqLyJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/wait-list/wait-list-form/wait-list-form.component.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;AAC7C;AACA;IACI,sCAAsC;AAC1C;;;AAGA;;GAEG;;AAEH;;GAEG;AACH,g/BAAg/B","sourcesContent":[".form-floating > .form-control:focus ~ label {\n    /* color: var(--bs-primary) !important; */\n}\n.form-floating > .form-control:not(:placeholder-shown) ~ label{\n    /* color: var(--bs-dark) !important; */\n}\n\n\n/* .form-floating > .form-control:not(:focus).ng-invalid.ng-touched ~ label {\n    color: var(--bs-danger) !important;\n} */\n\n/* .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label{\n    color: var(--bs-primary) !important;\n} */"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
