import { ScreenService } from './screen.service';
import {
  Injectable,
  EventEmitter,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ProgressSpinnerService } from './progress-spinner.service';

export enum DashboardSessionModeType {
  owner = 'owner',
  affiliate = 'affiliate',
}

export type SystemUiElementsCollection =
  | (
      | 'toolbar'
      | 'header'
      | 'sidebar'
      | 'app-container-padding'
      | 'item-default-head'
      | 'footer'
      | 'background-ext'
    )[]
  | string;

export type SystemUiMode =
  | ('sidebar-collapsed' | 'sidebar-minimized')[]
  | string;

@Injectable({
  providedIn: 'root',
})
export class UserDashboardService {
  uiComponentsStatus: { bgExt: boolean } = { bgExt: null };
  isMobile: boolean;
  isTablet: boolean;
  headerStickChange: Subject<boolean> = new Subject<boolean>();
  public headerStuck: boolean = false;
  public otherSideNotification: boolean = false;
  public dashboardSessionMode: DashboardSessionModeType =
    DashboardSessionModeType.owner;
  public tourEmitter: EventEmitter<boolean> = new EventEmitter();
  public internalLoaded = false;
  _enableAppContainerPadding = true;
  _showSidebar = true;
  _showHeader = true;
  _showItemDefaultHead = true;
  _showToolbar = true;
  _showFooter = true;
  isCollapsed: boolean = false;
  isMobileCollapsed: boolean = true;

  isFocusDimActive: boolean = false;

  constructor(
    private router: Router,
    public spinService: ProgressSpinnerService,
    private readonly zone: NgZone,
    private screenService: ScreenService
  ) {
    this.screenService.isBelowLg().subscribe((a) => {
      this.isMobile = a.matches;
    });
  }

  activateFocusDim() {
    this.isFocusDimActive = true;
    let focusElement = document.getElementById('focus-area');

    if (!!focusElement) {
      focusElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'start',
      });
      focusElement.style.setProperty('z-index', '20009');
    }
  }

  deactivateFocusDim() {
    this.isFocusDimActive = false;
    let focusElement = document.getElementById('focus-area');

    if (!!focusElement) {
      focusElement.style.removeProperty('z-index');
    }
  }

  toggleDarkMode(theme: 'dark' | 'light' = undefined) {
    let documentElement = document.documentElement;
    let currentStatus =
      documentElement.attributes.getNamedItem('data-bs-theme');
    if (!!theme) {
      currentStatus.value = theme;
      return currentStatus.value;
    }
    if (currentStatus.value == 'dark') {
      currentStatus.value = 'light';
    } else {
      currentStatus.value = 'dark';
    }

    return currentStatus.value;
  }

  stickyScrollHandle() {
    const scrollTop = document.documentElement.scrollTop;

    const offsetHeight = document.documentElement.offsetHeight;

    const scrollHeight = document.documentElement.scrollHeight;

    const scrollSafe = scrollHeight - offsetHeight;

    const scrollSenseStart = offsetHeight / 8;

    // add the 'stuck' class
    if (scrollTop >= scrollSenseStart && scrollSafe > scrollSenseStart) {
      this.headerStuck = true;
      // this.activateStickyHeader();
    }
    // remove the 'stuck' class
    else {
      this.headerStuck = false;
      this.deactivateStickyHeader();
    }
  }

  onDocumentReady(callback: () => void) {
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      // Document is already ready, execute callback immediately
      callback();
    } else {
      // Document is not ready, add event listener
      document.addEventListener('DOMContentLoaded', () => {
        callback();
      });
    }
  }

  private activateSidebarCollapseMode() {
    let bodyElement = document.body;

    bodyElement.setAttribute('data-learmo-app-sidebar-collapse', 'on');
    // this.closeCollapse();
  }

  private deactivateSidebarCollapseMode() {
    if (!this.isMobile) {
      let bodyElement = document.body;

      bodyElement.removeAttribute('data-learmo-app-sidebar-collapse');
      // this.openCollapse();
    }
  }

  private activateSidebarMinimizeMode() {
    if (!this.isMobile) {
      let bodyElement = document.body;

      bodyElement.setAttribute('data-kt-app-sidebar-minimize', 'on');
      this.closeCollapse();
    }
  }

  private deactivateSidebarMinimizeMode() {
    if (!this.isMobile) {
      let bodyElement = document.body;

      bodyElement.setAttribute('data-kt-app-sidebar-minimize', 'off');
      this.openCollapse();
    }
  }
  private deactivateStickyHeader() {
    let bodyElement = document.body;
    let headElement = document.getElementById('stickHeader');

    if (!headElement || !bodyElement) return;
    bodyElement.setAttribute('data-kt-app-header-minimize', 'off');
    let currentStatus = bodyElement.attributes.getNamedItem(
      'data-kt-app-header-sticky'
    );
    if (!currentStatus?.value) {
      bodyElement.setAttribute('data-kt-app-header-sticky', 'off');
    } else {
      currentStatus.value = 'off';
    }

    headElement.classList.remove('sticky-header-animation-init');
    this.headerStickChange.next(false);
  }
  private activateStickyHeader() {
    let bodyElement = document.body;
    let headElement = document.getElementById('stickHeader');

    bodyElement.setAttribute('data-kt-app-header-minimize', 'on');
    // bodyElement.setAttribute('data-kt-app-header-stacked', 'true');
    let currentStatus = bodyElement.attributes.getNamedItem(
      'data-kt-app-header-sticky'
    );
    if (!currentStatus?.value) {
      bodyElement.setAttribute('data-kt-app-header-sticky', 'on');
    } else {
      currentStatus.value = 'on';
    }
    headElement.classList.add('sticky-header-animation-init');
    setTimeout(() => {
      this.headerStickChange.next(true);
    }, 10);
  }

  closeMobileCollapse(timeout: number = 300) {
    if (this.isMobile) {
      this.isMobileCollapsed = true;
    }
  }

  openMobileCollapse(timeout: number = 500) {
    if (this.isMobile) {
      this.isMobileCollapsed = false;
    }
  }

  toggleMobileCollapse() {
    if (!!this.isMobileCollapsed) {
      this.openMobileCollapse();
    } else {
      this.closeMobileCollapse();
    }
  }

  closeCollapse(timeout: number = 300) {
    if (!this.isMobile) {
      this.isCollapsed = true;
    }
  }

  openCollapse(timeout: number = 500) {
    if (!this.isMobile) {
      this.isCollapsed = false;
    }
  }

  toggleCollapse() {
    if (!!this.isCollapsed) {
      this.openCollapse();
    } else {
      this.closeCollapse();
    }
  }

  setUiMode(uiMode: SystemUiMode[] | SystemUiMode) {
    
    uiMode?.includes('sidebar-minimized')
      ? this.activateSidebarMinimizeMode()
      : this.deactivateSidebarMinimizeMode();
    uiMode?.includes('sidebar-collapsed')
      ? this.activateSidebarCollapseMode()
      : this.deactivateSidebarCollapseMode();

    if (!uiMode?.length) {
      this.deactivateSidebarMinimizeMode();
      this.deactivateSidebarCollapseMode();
    }
  }

  // UI Elements Functions
  setUiElements(
    elementsToShow?: SystemUiElementsCollection,
    elementsToHide?: SystemUiElementsCollection
  ) {
    const allElements: SystemUiElementsCollection = [
      'toolbar',
      'header',
      'sidebar',
      'app-container-padding',
      'item-default-head',
      'footer',
      'background-ext',
    ];

    // If elementsToShow is empty, hide all elements
    if (elementsToShow === null) {
      elementsToHide = allElements;
    }

    // If elementsToHide is empty, show all elements
    if (
      elementsToShow === undefined &&
      (elementsToHide === null || elementsToHide === undefined)
    ) {
      elementsToShow = allElements;
    }

    

    const elementsActions = {
      toolbar: {
        show: () => this.showToolbar(),
        hide: () => this.hideToolbar(),
      },
      header: {
        show: () => this.showHeader(),
        hide: () => this.hideHeader(),
      },
      sidebar: {
        show: () => this.showSidebar(),
        hide: () => this.hideSidebar(),
      },
      'app-container-padding': {
        show: () => this.enableAppContainerPadding(),
        hide: () => this.disableAppContainerPadding(),
      },
      'item-default-head': {
        show: () => this.showItemDefaultHead(),
        hide: () => this.hideItemDefaultHead(),
      },
      footer: {
        show: () => this.showFooter(),
        hide: () => this.hideFooter(),
      },
      'background-ext': {
        show: () => this.showBackgroundExtension(),
        hide: () => this.hideBackgroundExtension(),
      },
    };

    allElements.forEach((element) => {
      if (
        elementsToShow?.includes(element) ||
        !elementsToHide?.includes(element)
      ) {
        elementsActions[element].show();
        
      } else {
        elementsActions[element].hide();
        
      }
    });
  }

  hideUi(
    elements: (
      | 'toolbar'
      | 'header'
      | 'sidebar'
      | 'app-container-padding'
      | 'item-default-head'
      | 'footer'
      | 'background-ext'
    )[] = [
      'toolbar',
      'header',
      'sidebar',
      'item-default-head',
      'app-container-padding',
      'footer',
      'background-ext',
    ]
  ) {
    if (elements?.includes('toolbar')) {
      this.hideToolbar();
    }
    if (elements?.includes('background-ext')) {
      this.hideBackgroundExtension();
    }
    if (elements?.includes('footer')) {
      this.hideFooter();
    }
    if (elements?.includes('app-container-padding')) {
      this.disableAppContainerPadding();
    }
    if (elements?.includes('header')) {
      this.hideHeader();
    }
    if (elements?.includes('item-default-head')) {
      this.hideItemDefaultHead();
    }
    if (elements?.includes('sidebar')) {
      this.hideSidebar();
    }
  }

  showUi(
    elements:
      | (
          | 'toolbar'
          | 'header'
          | 'sidebar'
          | 'app-container-padding'
          | 'item-default-head'
          | 'footer'
          | 'background-ext'
        )[]
      | string = [
      'toolbar',
      'header',
      'sidebar',
      'app-container-padding',
      'item-default-head',
      'footer',
      'background-ext',
    ]
  ) {
    if (elements?.includes('toolbar')) {
      this.showToolbar();
    }
    if (elements?.includes('background-ext')) {
      this.showBackgroundExtension();
    }
    if (elements?.includes('footer')) {
      this.showFooter();
    }
    if (elements?.includes('app-container-padding')) {
      this.enableAppContainerPadding();
    }
    if (elements?.includes('header')) {
      this.showHeader();
    }
    if (elements?.includes('item-default-head')) {
      this.showItemDefaultHead();
    }
    if (elements?.includes('sidebar')) {
      this.showSidebar();
    }
  }

  showSidebarAndHeader() {
    this.showSidebar();
    this.showHeader();
  }

  private hideHeader() {
    this._showHeader = false;
  }

  private showHeader() {
    this._showHeader = true;
  }

  private hideItemDefaultHead() {
    this._showItemDefaultHead = false;
  }

  private showItemDefaultHead() {
    this._showItemDefaultHead = true;
  }

  private hideFooter() {
    this._showFooter = false;
  }

  private showFooter() {
    this._showFooter = true;
  }

  private hideToolbar() {
    this._showToolbar = false;
  }

  private showToolbar() {
    this._showToolbar = true;
  }

  private hideSidebar() {
    let bodyElement = document.body;
    // let headElement = document.getElementById('stickHeader');
    bodyElement.setAttribute('data-kt-app-sidebar-enabled', 'false');
    bodyElement.setAttribute('data-kt-app-sidebar-fixed', 'false');
    bodyElement.setAttribute('data-kt-app-sidebar-push-toolbar', 'false');
    bodyElement.setAttribute('data-kt-app-sidebar-push-footer', 'false');
    bodyElement.setAttribute('data-kt-app-toolbar-enabled', 'false');
    bodyElement.setAttribute('data-kt-app-sidebar-push-header', 'false');

    this._showSidebar = false;
  }

  private showSidebar() {
    let bodyElement = document.body;
    // let headElement = document.getElementById('stickHeader');
    bodyElement.setAttribute('data-kt-app-sidebar-enabled', 'true');
    bodyElement.setAttribute('data-kt-app-sidebar-fixed', 'true');
    bodyElement.setAttribute('data-kt-app-sidebar-push-toolbar', 'true');
    bodyElement.setAttribute('data-kt-app-sidebar-push-footer', 'true');
    bodyElement.setAttribute('data-kt-app-toolbar-enabled', 'true');
    bodyElement.setAttribute('data-kt-app-sidebar-push-header', 'true');

    this._showSidebar = true;
  }

  showBackgroundExtension(notify: boolean = true) {
    let bodyElement = document.body;
    bodyElement.classList.add('show-bg');

    if (notify) {
      this.uiComponentsStatus.bgExt = true;
    }
  }

  hideBackgroundExtension(notify: boolean = true) {
    let bodyElement = document.body;
    bodyElement.classList.remove('show-bg');

    if (notify) {
      this.uiComponentsStatus.bgExt = false;
    }
  }

  private enableAppContainerPadding() {
    this._enableAppContainerPadding = true;
  }

  private disableAppContainerPadding() {
    this._enableAppContainerPadding = false;
  }

  // Affiliate And Owner Modes
  async navigateToAffiliateMode() {
    this.spinService.showLoader('Switching');
    this.switchToAffiliateMode();
    await this.router.navigate(['/affiliate']);
    this.spinService.hideLoader();
  }

  async navigateToOwnerMode() {
    this.spinService.showLoader('Switching');
    this.switchToOwnerMode();
    await this.router.navigate(['/dashboard']);
    this.spinService.hideLoader();
  }

  enableOtherSideNotification() {
    this.otherSideNotification = true;
  }

  disableOtherSideNotification() {
    this.otherSideNotification = false;
  }

  switchToAffiliateMode() {
    this.dashboardSessionMode = DashboardSessionModeType.affiliate;
  }

  switchToOwnerMode() {
    this.dashboardSessionMode = DashboardSessionModeType.owner;
  }
}
