import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { SharedProfileService } from 'src/app/components/profile/shared-profile.service';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { SocketConnectionStringStatus } from './../../services/portal-socket.service';
import { ScreenService } from './../../services/screen.service';

import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models';
import { slideInOut, slider } from '../../animations/animations';
import { SharedMessengerService } from '../../partials/messenger/shared-messenger.service';
import { AuthenticationService } from '../../services';
import { NotificationsService } from '../../services/notifications.service';
import { PortalSocketService } from '../../services/portal-socket.service';
import { UserDashboardService } from '../../services/user-dashboard.service';
import {
  isAccountSetupStepsDone,
  isAccountSetupStepsLoadingError,
} from '../../store/_features/account-setup/account-setup.selector';
import { SystemPlansActions } from '../../store/_features/subscription/subscription.actions';
import { websiteActions } from '../../store/_features/website/website.actions';
import { selectIsWebsiteReady } from '../../store/_features/website/website.selector';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './owner-dashboard.component.html',
  styleUrls: ['./owner-dashboard.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s ease-in', style({ opacity: 0 })),
      ]),
    ]),
    slider,
  ],
})
export class OwnerDashboardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public loaded: boolean = false;
  public headerStuck: boolean = false;
  public socketConnectionStringStatus = SocketConnectionStringStatus;
  public innerWidth: number;

  @ViewChild('portalsTooltip') portalsTooltipRef: NgbTooltip;
  public currentUser: User;
  public destroy = new Subject();

  public destroy$ = this.destroy.asObservable();

  public scrollSidebar: boolean = false;
  public _toggleSidebar = true;

  public collapsingTimeout: ReturnType<typeof setTimeout>;

  public isWebsiteReady: boolean = null;

  public isStepsDone: boolean = false;
  public isStepsLoadingError: boolean = false;
  constructor(
    public userDashboardService: UserDashboardService,
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public socketService: PortalSocketService,
    private route: Router,
    public notificationsService: NotificationsService,
    public sharedMessengerService: SharedMessengerService,
    public sharedProfileService: SharedProfileService,
    private readonly zone: NgZone,
    private store: Store,
    private authenticationService: AuthenticationService,
    private router: Router,
    private progressSpinnerService: ProgressSpinnerService
  ) {
    // this.store.dispatch(isWebhookDone())

    this.store.select(isAccountSetupStepsLoadingError).subscribe((isError) => {
      this.isStepsLoadingError = !!isError;
    });

    this.store.select(isAccountSetupStepsDone).subscribe((isDone) => {
      this.isStepsDone = isDone;
    });

    this.store.select(selectIsWebsiteReady).subscribe((value) => {
      if (value == false) {
        this.progressSpinnerService.showLoader('Loading...');
        if (this.userDashboardService.uiComponentsStatus.bgExt === null) {
          this.userDashboardService.hideBackgroundExtension(false);
        }
        this.isWebsiteReady = value;
      } else if (value == true) {
        this.isWebsiteReady = value;
        this.progressSpinnerService.hideLoader();
        if (this.userDashboardService.uiComponentsStatus.bgExt === null) {
          this.userDashboardService.showBackgroundExtension(false);
        }
      } else {
        this.isWebsiteReady = null;
        this.progressSpinnerService.showLoader('Loading...');
        if (this.userDashboardService.uiComponentsStatus.bgExt === null) {
          this.userDashboardService.hideBackgroundExtension(false);
        }
      }
    });

    this.authenticationService.currentUser.subscribe((value: User) => {
      this.currentUser = value;
    });

    // route.events.subscribe((val) => {
    //   this.toggleSidebar(false);
    // });

    // fromEvent(window, 'scroll')
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((e: Event) => {
    //     this.userDashboardService.stickyScrollHandle();
    //   });
  }

  @HostListener('window:scroll', []) private onScroll($event: Event): void {
    this.zone.run(() => {
      this.userDashboardService.stickyScrollHandle();
    });
  }

  // @HostListener('click')
  // clicked() {
  //   this.userDashboardService.closeCollapse();
  // }

  public showSidebarWithFloat() {}

  public helperTooltipInitialize() {
    this.portalsTooltipRef?.open();
    setTimeout(() => {
      this.portalsTooltipRef?.close();
    }, 4000);
  }

  public async ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.userDashboardService.switchToOwnerMode();
    this.sharedMessengerService.getMessengerThreads(
      undefined,
      undefined,
      undefined,
      undefined,
      false
    );
    await this.notificationsService.getOwnerNotificationsList();

    this.getCurrentWebsite();

    this.getSystemPlans();
    this.sharedProfileService.getSubscriptionInfo()
  }

  public ngAfterViewInit(): void {
    const el: HTMLElement = document.getElementById('stickHeader');
    // add an event listener for scrolling
    this.helperTooltipInitialize();
  }

  public ngOnDestroy(): void {
    this.notificationsService.endOwnerNotificationsListening();
    this.notificationsService.endMessengerListening();
  }

  public async getCurrentWebsite() {
    this.store.dispatch(websiteActions.load());
    
  }

  public async getSystemPlans() {
    this.store.dispatch(SystemPlansActions.load());
  }

  public repeatTour() {
    this.userDashboardService.tourEmitter.emit();
  }

  public toggleSidebar(show: boolean = true) {
    this._toggleSidebar = !this._toggleSidebar;
  }

  public minimizedSidebarIn() {
    document.body.classList.add('sidebar-openner-hover');
  }

  public minimizedSidebarOut() {
    document.body.classList.remove('sidebar-openner-hover');
  }
}
