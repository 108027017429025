
import { NewVersionAnnouncementComponent } from './shared/layout/owner-dashboard/new-version-announcement/new-version-announcement.component';

import { LearmoStoreModule } from './shared/store/store.module';

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TitleBreadcrumbsHeadComponent } from './shared/layout/owner-dashboard/title-breadcrumbs-head/title-breadcrumbs-head.component';
import { VersionCheckService } from './shared/services/version-check.service';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, components } from './app-routing.module';
import { AppComponent } from './app.component';

import { errorInterceptor } from './interceptors/error.interceptor';
import { jwtInterceptor } from './interceptors/jwt.interceptor';

import { CanDeactivateDashboardGuard } from './shared/guards/dashboardnav.guard';
import { SharedModule } from './shared/shared.module';

import { CommonModule } from '@angular/common';

import { FullComponent } from './shared/layout/full/full.component';

import { TagInputModule } from 'ngx-chips';
import { PlaygroundComponent } from './components/playground/playground.component';
import { AffiliateDashboardComponent } from './shared/layout/affiliate-dashboard/affiliate-dashboard.component';
import { OwnerDashboardComponent } from './shared/layout/owner-dashboard/owner-dashboard.component';
import { SocketStatusComponent } from './shared/layout/owner-dashboard/socker-status/socket-status.component';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    OwnerDashboardComponent,
    NewVersionAnnouncementComponent,
    SocketStatusComponent,
    AffiliateDashboardComponent,
    TitleBreadcrumbsHeadComponent,
    PlaygroundComponent,
  ],
  imports: [
    TagInputModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,

    AppRoutingModule,
    SharedModule,
    LearmoStoreModule,
    
    // PortalModule,
    // TutorialsModule,
    // ProductModule,
    // OfferModule,
    // DomainsModule,
    // CoursesModule,
    // ProfileModule,
    // AffiliateModule,
    // AffiliateDashboardModule,

    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
  ],
  providers: [
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])),
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CanDeactivateDashboardGuard,
    VersionCheckService,
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(
    // private store: Store
    ) {
    
  }
}
// platformBrowserDynamic().bootstrapModule(AppModule);
