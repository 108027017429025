// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* socket Component Styling */
@keyframes connection-done {
  100% {
    margin-top: -50px;
    opacity: 0;
    z-index: 0;
  }
}

.connection-done {
  animation: connection-done 0.5s forwards cubic-bezier(0.32, 0.56, 1, 0.6);
  animation-delay: 1500ms;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNvY2tldC1zdGF0dXMuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSw2QkFBNkI7QUFDN0I7RUFDRTtJQUNFLGlCQUFpQjtJQUNqQixVQUFVO0lBQ1YsVUFBVTtFQUNaO0FBQ0Y7O0FBRUE7RUFDRSx5RUFBeUU7RUFDekUsdUJBQXVCO0FBQ3pCIiwiZmlsZSI6InNvY2tldC1zdGF0dXMuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi8qIHNvY2tldCBDb21wb25lbnQgU3R5bGluZyAqL1xuQGtleWZyYW1lcyBjb25uZWN0aW9uLWRvbmUge1xuICAxMDAlIHtcbiAgICBtYXJnaW4tdG9wOiAtNTBweDtcbiAgICBvcGFjaXR5OiAwO1xuICAgIHotaW5kZXg6IDA7XG4gIH1cbn1cblxuLmNvbm5lY3Rpb24tZG9uZSB7XG4gIGFuaW1hdGlvbjogY29ubmVjdGlvbi1kb25lIDAuNXMgZm9yd2FyZHMgY3ViaWMtYmV6aWVyKDAuMzIsIDAuNTYsIDEsIDAuNik7XG4gIGFuaW1hdGlvbi1kZWxheTogMTUwMG1zO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/socker-status/socket-status.component.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE;IACE,iBAAiB;IACjB,UAAU;IACV,UAAU;EACZ;AACF;;AAEA;EACE,yEAAyE;EACzE,uBAAuB;AACzB;;AAEA,4tBAA4tB","sourcesContent":["/* socket Component Styling */\n@keyframes connection-done {\n  100% {\n    margin-top: -50px;\n    opacity: 0;\n    z-index: 0;\n  }\n}\n\n.connection-done {\n  animation: connection-done 0.5s forwards cubic-bezier(0.32, 0.56, 1, 0.6);\n  animation-delay: 1500ms;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
