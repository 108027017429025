// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .nav-link {
  color: var(--bs-text-dark) !important;
  font-weight: 400 !important;
}

.nav-link.active {
  color: var(--bs-text-primary) !important;
  font-weight: 600 !important;
} */

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR5bmFtaWMtYWN0aW9uLWJ1dHRvbnMuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7Ozs7Ozs7R0FRRyIsImZpbGUiOiJkeW5hbWljLWFjdGlvbi1idXR0b25zLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiAubmF2LWxpbmsge1xuICBjb2xvcjogdmFyKC0tYnMtdGV4dC1kYXJrKSAhaW1wb3J0YW50O1xuICBmb250LXdlaWdodDogNDAwICFpbXBvcnRhbnQ7XG59XG5cbi5uYXYtbGluay5hY3RpdmUge1xuICBjb2xvcjogdmFyKC0tYnMtdGV4dC1wcmltYXJ5KSAhaW1wb3J0YW50O1xuICBmb250LXdlaWdodDogNjAwICFpbXBvcnRhbnQ7XG59ICovXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/dynamic-preview-buttons/dynamic-action-buttons.component.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH,oiBAAoiB","sourcesContent":["/* .nav-link {\n  color: var(--bs-text-dark) !important;\n  font-weight: 400 !important;\n}\n\n.nav-link.active {\n  color: var(--bs-text-primary) !important;\n  font-weight: 600 !important;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
