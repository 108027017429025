// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-invisible {
    visibility: hidden !important;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
    /* margin-top: 0 !important; */
  }
  
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ0Y3BheS1pbnRlZ3JhdGlvbi1mb3JtLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSw2QkFBNkI7SUFDN0IsU0FBUztJQUNULHFCQUFxQjtJQUNyQixvQkFBb0I7SUFDcEIsOEJBQThCO0VBQ2hDIiwiZmlsZSI6ImJ0Y3BheS1pbnRlZ3JhdGlvbi1mb3JtLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZXJyb3ItaW52aXNpYmxlIHtcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW4gIWltcG9ydGFudDtcbiAgICBoZWlnaHQ6IDA7XG4gICAgcGFkZGluZzogMCAhaW1wb3J0YW50O1xuICAgIG1hcmdpbjogMCAhaW1wb3J0YW50O1xuICAgIC8qIG1hcmdpbi10b3A6IDAgIWltcG9ydGFudDsgKi9cbiAgfVxuICAiXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-payment/integrate/method-form/btcpay/btcpay-integration-form/btcpay-integration-form.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,SAAS;IACT,qBAAqB;IACrB,oBAAoB;IACpB,8BAA8B;EAChC;;AAEF,4kBAA4kB","sourcesContent":[".error-invisible {\n    visibility: hidden !important;\n    height: 0;\n    padding: 0 !important;\n    margin: 0 !important;\n    /* margin-top: 0 !important; */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
