import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationSkipped,
  Router,
} from '@angular/router';
import { AnalyticsService } from './shared/services/analytics.service';
import {
  AfterViewInit,
  Component,
  OnInit,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbDropdownConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { IStaticMethods } from 'preline/preline';
import { filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { followCursor } from 'tippy.js';
import { InOutAnimation } from './shared/animations/animations';
import { LoggingService } from './shared/services/logging.service';
import {
  SystemUiElementsCollection,
  SystemUiMode,
  UserDashboardService,
} from './shared/services/user-dashboard.service';
import { VersionCheckService } from './shared/services/version-check.service';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [InOutAnimation],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public defaultPageTitle = 'learmo';
  private destroy$ = new Subject<void>();
  constructor(
    public userDashboardService: UserDashboardService,
    private router: Router,
    private route: ActivatedRoute,
    public dropdownConfig: NgbDropdownConfig,
    private title: Title,
    private modalConfig: NgbModalConfig,
    private tippyService: NgxTippyService,
    public versionCheckService: VersionCheckService,
    private loggingService: LoggingService,
    private analyticsService: AnalyticsService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.configureTippy();
    this.configureDropdown();
    this.configureModal();
    this.brandedLog();
    this.setupRouterEvents();
  }

  ngAfterViewInit(): void {
    this.initializePreline();
    this.setupMutationObserver();

  }

  // MODIFIED initializePreline() method
 // MODIFIED initializePreline() method
private initializePreline(): void {
  if (typeof window === 'undefined' || !window.HSStaticMethods) { return; }

  // Use double RAF for better timing with Angular's change detection
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      try {
        // More comprehensive element detection
        const hasDynamicElements = [
          '[data-hs-overlay]',
          '[data-hs-collapse]',
          '.hs-accordion',
          '.hs-dropdown'
        ].some((selector) => document.querySelector(selector));

        if (hasDynamicElements) {
          window.HSStaticMethods.autoInit();
          
          // Add debug logging
          this.loggingService.debug(
            'Preline initialized', 
            Array.from(document.querySelectorAll('[data-hs-collapse], [data-hs-dropdown]'))
          );
        }
      } catch (error) {
        this.loggingService.error('Preline initialization failed', error);
      }
    });
  });
}

private setupMutationObserver() {
  const observer = new MutationObserver(() => {
    this.initializePreline();
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

  private configureTippy(): void {
    this.tippyService.setDefaultProps({
      plugins: [followCursor],
      placement: 'auto',
      followCursor: true,
      theme: 'light',
      arrow: true,
      delay: [200, 0],
      duration: [190, 0],
      zIndex: 999999999999,
      animation: 'shift-toward-extreme',
      allowHTML: true,
    });
  }

  private configureDropdown(): void {
    this.dropdownConfig.container = 'body';
    this.dropdownConfig.autoClose = true;
  }

  private configureModal(): void {
    this.modalConfig.animation = false;
  }

  private setupRouterEvents(): void {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationSkipped
        ),
        switchMap(() => {
          const routeUiData = this.extractRouteUiData(this.route.snapshot);
          const routeTitle = this.extractRouteTitle(this.route.snapshot);
          return of({
            ...routeUiData,
            title: routeTitle,
          });
        }),
        takeUntil(this.destroy$) // Cleanup on component destroy
      )
      .subscribe((data) => {
        this.userDashboardService.setUiMode(data.uiMode);
        this.userDashboardService.setUiElements(
          data.hideUiExcept,
          data.showUiExcept
        );

        if (data?.title && data.title !== this.defaultPageTitle) {
          this.title.setTitle(data.title);
        }

        // Initialize Preline after UI updates
        this.initializePreline();
      });
  }
  public extractRouteTitle(child: any): string {
    if (!child) {
      return this.route.snapshot.data.title || this.defaultPageTitle;
    }

    while (child.firstChild) {
      child = child.firstChild;
    }

    return child.snapshot?.data?.title || this.defaultPageTitle;
  }

  public extractRouteUiData(child: ActivatedRouteSnapshot): {
    showUiExcept: SystemUiElementsCollection;
    hideUiExcept: SystemUiElementsCollection;
    uiMode: SystemUiMode;
  } {
    let showUiExcept: SystemUiElementsCollection = null;
    let hideUiExcept: SystemUiElementsCollection = null;
    let uiMode: SystemUiMode = null;

    while (child) {
      showUiExcept = this.getUiData(child, 'showUiExcept');
      hideUiExcept = this.getUiData(child, 'hideUiExcept');

      if (child?.data?.uiMode) {
        uiMode = Array.isArray(child.data.uiMode)
          ? [...child.data.uiMode]
          : child.data.uiMode;
      }

      child = child.firstChild;
    }

    return { showUiExcept, hideUiExcept, uiMode };
  }

  private getUiData(
    child: ActivatedRouteSnapshot,
    key: string
  ): SystemUiElementsCollection {
    if (!!child?.data?.[key]?.length) {
      return child.data[key];
    } else if (child?.data?.[key] === undefined) {
      return undefined;
    } else {
      return null;
    }
  }

  public scrollTo(
    elementID: string,
    position: ScrollLogicalPosition = 'nearest'
  ): void {
    document.getElementById(elementID)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
    });
  }

  public brandedLog(): void {
    console.log(
      '%c🫦😂😅\n learmo%c.com💙\n%c - Monetize your knowledge! -',
      'filter: invert(1); font-size: 48px; font-weight: bolder; font-family: "tahoma"; margin-top: 20px; margin-bottom: 8px;',
      'color: #0d6efd; font-size: 28px; font-weight: thin; font-family: "tahoma"',
      'color: #B5B5C3; font-size: 16px; font-family: "tahoma"; margin-bottom: 20px;'
    );
  }

  public onActivate(event: any): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
