// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item.active, .dropdown-item:active {
  color: #141414 !important;
  background-color: #dbdbdb !important;
}

/* .dropdown-item:focus {
  background-color: #fff !important;
} */

.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {
  color: #F64E60 !important;
  background-color: #dbdbdb !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFmZmlsaWF0ZS1kcm9wLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSx5QkFBeUI7RUFDekIsb0NBQW9DO0FBQ3RDOztBQUVBOztHQUVHOztBQUVIO0VBQ0UseUJBQXlCO0VBQ3pCLG9DQUFvQztBQUN0QyIsImZpbGUiOiJhZmZpbGlhdGUtZHJvcC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmRyb3Bkb3duLWl0ZW0uYWN0aXZlLCAuZHJvcGRvd24taXRlbTphY3RpdmUge1xuICBjb2xvcjogIzE0MTQxNCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZGJkYmRiICFpbXBvcnRhbnQ7XG59XG5cbi8qIC5kcm9wZG93bi1pdGVtOmZvY3VzIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZiAhaW1wb3J0YW50O1xufSAqL1xuXG4uZHJvcGRvd24taXRlbS50ZXh0LWRhbmdlci5hY3RpdmUsIC5kcm9wZG93bi1pdGVtLnRleHQtZGFuZ2VyOmFjdGl2ZSB7XG4gIGNvbG9yOiAjRjY0RTYwICFpbXBvcnRhbnQ7XG4gIGJhY2tncm91bmQtY29sb3I6ICNkYmRiZGIgIWltcG9ydGFudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/user-drop/affiliate-drop/affiliate-drop.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA,4yBAA4yB","sourcesContent":[".dropdown-item.active, .dropdown-item:active {\n  color: #141414 !important;\n  background-color: #dbdbdb !important;\n}\n\n/* .dropdown-item:focus {\n  background-color: #fff !important;\n} */\n\n.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {\n  color: #F64E60 !important;\n  background-color: #dbdbdb !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
