import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  AfterViewInit,
  Input,
  Host,
  OnDestroy,
  Output,
  Injectable,
  signal,
  computed,
  effect,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';

// Service to manage dropdown states using signals
@Injectable({ providedIn: 'root' })
export class LearmoDropdownService {
  private openDropdowns = signal<Set<string>>(new Set());

  isDropdownOpen(dropdownId: string): boolean {
    return this.openDropdowns().has(dropdownId);
  }

  openDropdownState(dropdownId: string): void {
    this.openDropdowns.update((set) => new Set([...set, dropdownId]));
  }

  closeDropdownState(dropdownId: string): void {
    this.openDropdowns.update((set) => {
      const newSet = new Set(set);
      newSet.delete(dropdownId);
      return newSet;
    });
  }

  toggleDropdownState(dropdownId: string, forceState?: boolean): void {
    const isOpen = this.isDropdownOpen(dropdownId);
    const newState = forceState !== undefined ? forceState : !isOpen;
    if (newState) {
      this.openDropdownState(dropdownId);
    } else {
      this.closeDropdownState(dropdownId);
    }
  }

  closeAllDropdowns(except: string[] = []): void {
    this.openDropdowns().forEach((value: string) => {
      if (except.includes(value)) {return};
      this.closeDropdownState(value);
      // Your logic here
    });
    // this.openDropdowns.update((set) => {
    //   if (except.length > 0) {
    //     return new Set(except.filter((id) => set.has(id)));
    //   } else {
    //     return new Set();
    //   }
    // });
  }
}

// Directive for the dropdown container
@Directive({
  selector: '[learmoDropdown]',
  exportAs: 'learmoDropdown',
})
export class LearmoDropdownDirective implements AfterViewInit, OnDestroy {
  @Input() autoClose: boolean = true;
  @Input() position: 'auto' | 'top' | 'bottom' = 'auto';
  @Output() openChange = new EventEmitter<boolean>();

  private dropdownMenu: HTMLElement | null = null;
  private dropdownButton: HTMLElement | null = null;
  private dropdownId: string = String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now();
  private subscriptions: Subscription[] = [];
  public isOpen = signal<boolean>(false);

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private dropdownService: LearmoDropdownService
  ) {
    // React to changes in the isOpen signal
    effect(() => {
      const isOpen = this.dropdownService.isDropdownOpen(this.dropdownId);
      if (this.dropdownMenu) {
        const action = isOpen ? 'addClass' : 'removeClass';
        this.renderer[action](this.dropdownMenu, 'show');
        this.setPosition();
      }
      this.openChange.emit(isOpen);
    });
  }

  ngAfterViewInit(): void {
    this.dropdownButton = this.el.nativeElement.querySelector('[learmoDropdownButton]');
    this.dropdownMenu = this.el.nativeElement.querySelector('[learmoDropdownMenu]');

    if (this.dropdownMenu) {
      this.renderer.addClass(this.dropdownMenu, 'learmo-dropdown-menu');
      document.body.appendChild(this.dropdownMenu);
    }

    if (this.dropdownButton) {
      this.renderer.listen(this.dropdownButton, 'click', (event: MouseEvent) => {
        event.stopPropagation();
        this.toggleDropdown();
      });
    }

    this.setPosition();
  }

  ngOnDestroy(): void {
      // Close the dropdown when the component is destroyed
    this.dropdownService.closeDropdownState(this.dropdownId);

    // Explicitly remove the 'show' class from the dropdown menu
    if (this.dropdownMenu) {
      this.renderer.removeClass(this.dropdownMenu, 'show');
    }

    if (this.dropdownMenu && this.el.nativeElement.contains(this.dropdownMenu)) {
      this.renderer.appendChild(this.el.nativeElement, this.dropdownMenu);
    }
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.toggleDropdown();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.autoClose && this.isOpen() && !this.el.nativeElement.contains(event.target)) {
      this.toggleDropdown(false);
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (!this.isOpen()) return;

    const items = this.dropdownMenu?.querySelectorAll('a, button');
    const activeItem = Array.from(items || []).find((item: HTMLElement) =>
      item.classList.contains('active')
    ) as HTMLElement;
    let index = items ? Array.from(items).indexOf(activeItem) : -1;

    switch (event.key) {
      case 'ArrowDown':
        index = (index + 1) % (items?.length || 0);
        break;
      case 'ArrowUp':
        index = (index - 1 + (items?.length || 0)) % (items?.length || 0);
        break;
      case 'Escape':
        this.toggleDropdown(false);
        return;
    }

    if (items?.[index]) {
      (items[index] as HTMLElement).focus();
      items[index].classList.add('active');
    }
  }

  closeAllDropdowns(): void {
    this.dropdownService.closeAllDropdowns([this.dropdownId]);
    // Ensure the current dropdown's state is updated
    this.isOpen.set(this.dropdownService.isDropdownOpen(this.dropdownId));
  }

  toggleDropdown(forceState?: boolean): void {
    this.closeAllDropdowns();
    const newState = forceState !== undefined ? forceState : !this.isOpen();
    this.isOpen.set(newState);
    this.dropdownService.toggleDropdownState(this.dropdownId, newState);
  }

  private setPosition(): void {
    if (!this.dropdownMenu || !this.dropdownButton) {
      console.warn('Dropdown elements not found');
      return;
    }
  
    const buttonRect = this.dropdownButton.getBoundingClientRect();
    const menuHeight = this.dropdownMenu.offsetHeight;
    const menuWidth = this.dropdownMenu.offsetWidth;
  
    let leftPosition = buttonRect.left;
    let topPosition: number;
  
    const spaceBelow = window.innerHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;
  
    // Calculate the available space on the right and left of the button
    const spaceRight = window.innerWidth - buttonRect.right;
    const spaceLeft = buttonRect.left;
  
    // Determine the best position for the dropdown menu
    if (this.position === 'auto') {
      // Check if there is enough space below the button
      if (spaceBelow >= menuHeight) {
        topPosition = buttonRect.bottom + window.scrollY; // Add scrollY to account for scrolling
      } else if (spaceAbove >= menuHeight) {
        // If not enough space below, check if there is enough space above
        topPosition = buttonRect.top - menuHeight + window.scrollY; // Add scrollY to account for scrolling
      } else {
        // If not enough space either above or below, place it where there is more space
        topPosition = spaceBelow >= spaceAbove
          ? buttonRect.bottom + window.scrollY // Add scrollY to account for scrolling
          : buttonRect.top - menuHeight + window.scrollY; // Add scrollY to account for scrolling
      }
    } else if (this.position === 'bottom') {
      topPosition = buttonRect.bottom + window.scrollY; // Add scrollY to account for scrolling
    } else {
      topPosition = buttonRect.top - menuHeight + window.scrollY; // Add scrollY to account for scrolling
    }
  
    // Adjust the left position to ensure the dropdown menu stays within the viewport
    if (leftPosition + menuWidth > window.innerWidth) {
      leftPosition = window.innerWidth - menuWidth;
    }
    leftPosition = Math.max(0, leftPosition);
  
    // Adjust the top position to ensure the dropdown menu stays within the viewport
    if (topPosition + menuHeight > window.innerHeight + window.scrollY) {
      topPosition = window.innerHeight + window.scrollY - menuHeight;
    }
    topPosition = Math.max(window.scrollY, topPosition); // Ensure the dropdown doesn't go above the viewport
  
    // Apply the calculated positions to the dropdown menu
    this.renderer.setStyle(this.dropdownMenu, 'position', 'absolute');
    this.renderer.setStyle(this.dropdownMenu, 'left', `${Math.round(leftPosition)}px`);
    this.renderer.setStyle(this.dropdownMenu, 'top', `${Math.round(topPosition)}px`);
  }
}

// Directive for the dropdown button
@Directive({ selector: '[learmoDropdownButton]' })
export class LearmoDropdownButtonDirective {
  constructor(@Host() private parentDropdown: LearmoDropdownDirective) {}
}

// Directive for the dropdown menu
@Directive({ selector: '[learmoDropdownMenu]' })
export class LearmoDropdownMenuDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // Initialization logic if needed
  }
}

// Directive for dropdown menu items
@Directive({ selector: '[learmoDropdownItem]' })
export class LearmoDropdownItemDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseover') onHover(): void {
    // Hover logic if needed
  }

  @HostListener('mouseout') onMouseOut(): void {
    // Mouse out logic if needed
  }
}

export const LearmoDropdownDirectives = [
  LearmoDropdownDirective,
  LearmoDropdownButtonDirective,
  LearmoDropdownMenuDirective,
  LearmoDropdownItemDirective,
];