import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { User } from '../models';
import { AuthenticationService } from '../shared/services';
import { AppwriteService } from '../shared/services/appwrite.sdk.service';

export const jwtInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<any> => {
  const authenticationService = inject(AuthenticationService);
  const appwriteService = inject(AppwriteService);

  const disableInterceptor = req.headers.get('disableInterceptor');
  if (disableInterceptor) {
    return next(req);
  }

  const token = authenticationService.currentTokenValue;
  const currentUserProfile: User = authenticationService.currentUserValue;

  if (!token && !currentUserProfile?.id) {
    return next(req);
  }

  if (appwriteService.isTokenValidParse(token, { URL: req.url })) {
    const modifiedReq = addAuthorizationHeader(req, token);
    return next(modifiedReq);
  } else {
    return handleBrokenToken(req, next, authenticationService);
  }
};

const addAuthorizationHeader = (
  req: HttpRequest<any>,
  token: string
): HttpRequest<any> => {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const handleBrokenToken = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  authenticationService: AuthenticationService
): Observable<any> => {
  if (!authenticationService.isRefreshingToken) {
    authenticationService.isRefreshingToken = true;

    return authenticationService.refreshTokenObservable().pipe(
      mergeMap((newToken: string) => {
        
        authenticationService.refreshTokenSubject.next(newToken);
        authenticationService.isRefreshingToken = false;

        const updatedReq = addAuthorizationHeader(req, newToken);
        console.info('Retrying request:', req.url);
        return next(updatedReq);
      }),
      catchError(() => {
        authenticationService.logout(true);
        authenticationService.isRefreshingToken = false;
        return throwError(() => new Error('Unauthorized'));
      })
    );
  } else {
    return authenticationService.refreshTokenSubject.pipe(
      filter((token) => !!token),
      take(1),
      switchMap((newToken) => {
        const updatedReq = addAuthorizationHeader(req, newToken);
        console.info('Retrying request with refreshed token:', req.url);
        return next(updatedReq);
      })
    );
  }
};
