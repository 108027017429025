import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ThemeMediaTypeEnum,
  TemplateMediaItem,
  LoginMediaItem,
} from 'src/app/components/user-dashboard/portal/portal.model';
import { SharedProductService } from 'src/app/components/user-dashboard/product/shared-product.service';
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  SwiperOptions,
  Swiper,
} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-funnel-onboarding-gallery',
  templateUrl: './funnel-onboarding.component.html',
  styleUrls: ['./funnel-onboarding.component.css'],
})
export class FunnelOnboardingGalleryComponent implements OnInit {
  // @Input() mainImage: TemplateMediaItem;
  // @Input() images: TemplateMediaItem[];

  @Output() onDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  themeMediaTypeEnum = ThemeMediaTypeEnum;
  thumbsSwiper: any;
  swiperController: SwiperCore;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoplay: false,
    navigation: {
      enabled: false,
    },
    direction: 'horizontal',
    pagination: {
      clickable: true,
      dynamicBullets: false,
      bulletActiveClass: 'swiper-pagination-bullet-active w-[25px] rounded',
      bulletClass: 'swiper-pagination-bullet',
      clickableClass: 'swiper-pagination-clickable',
      currentClass: 'swiper-pagination-current',
    },
    effect: 'slide',
    grabCursor: false,
    allowTouchMove: false,
    centeredSlides: true,
    zoom: {
      maxRatio: 2,
      toggle: true,
    },
    // preventClicks: true,
    // preventClicksPropagation: true,
    on: {
      slideChange: this.onSlideChange,
    },
  };
  _loadedImages: boolean = true;
  _images: LoginMediaItem[] = [
    {
      "title": "🌟 Unlock Success with Blueprints",
      "content": "Our blueprints are your secret weapon! These proven templates and strategies will guide you towards success in creating effective funnels.",
      "image": "assets/images/svg/system/onboarding/funnel/blueprints.svg?rand=1.2",
      "type": ThemeMediaTypeEnum.image
    },
    {
      title: '💼 Your Path to Success Begins Here',
      content: `Success begins with your first funnel. We'll help you create a winning strategy and achieve your goals. Let's do this!`,
      image: 'assets/images/svg/system/onboarding/funnel/success.svg',
      type: ThemeMediaTypeEnum.image
    },
    {
      "title": "🚀 Let's Get Started - Your First Funnel",
      "content": "Ready to dive in? Let's start building your very first sales/marketing funnel. It's easier than you think!",
      "image": "assets/images/svg/system/onboarding/funnel/funnel.svg?rand=1.2",
      "type": ThemeMediaTypeEnum.image
    }
  ];

  constructor() {}

  @Input('images') set images(value: LoginMediaItem[]) {
    // this._images = value;
    this._images.push(...value);

    this._loadedImages = true;
  }

  goNext() {
    this.swiperController.slideNext();
  }

  goBack() {
    this.swiperController.slidePrev();
  }

  onSwiper(value) {
    this.swiperController = value;
  }
  onSlideChange(swiper: SwiperCore) {
    // if (swiper.slides.length == swiper.activeIndex +1) {
    // }
    // else
  }

  ngOnInit(): void {
    // this._images = [
    //   new ImageItem({ src: this.mainImage.url, thumb: this.mainImage.url }),
    //   // ... more items
    // ];
  }
}
