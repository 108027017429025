import { MediaLibraryFileModel } from './../../../models/sales-funnel';
import {
  UserWebsite, WebsiteIdentity, WebsiteModel,
} from './../../../components/user-dashboard/portal/portal.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-browser-website-view',
  templateUrl: './browser-website-view.component.html',
  styleUrls: ['./browser-website-view.component.css'],
})
export class BrowserWebsiteViewComponent implements OnInit {
  @Input() url: string = '';
  @Input() layout: string = 'layout-1';

  @Input() favicon: MediaLibraryFileModel;
  @Input() website:
    | { siteUrl?: string; identity: Partial<WebsiteIdentity> }
    | Partial<WebsiteModel>;
  constructor() {}

  ngOnInit(): void {}

  onUrlSubmit(event: Event): void {
    event.preventDefault();
    if (this.url) {
      // Add logic to handle URL submission
      
      this.previewURL(this.url);
    }
  }

  onLayoutChange(): void {
    // Add logic to handle layout changes
    
    this.applyLayout(this.layout);
  }

  previewURL(url: string): void {
    // Logic to preview the URL in different device layouts
    const devicePreviews = document.querySelectorAll('.device iframe');
    devicePreviews.forEach((iframe) => {
      (iframe as HTMLIFrameElement).src = url;
    });
  }

  applyLayout(layout: string): void {
    // Logic to change the layout of device previews
    const layoutClassMap = {
      'layout-1': 'all-devices',
      'layout-2': 'mobile-tablet',
      'layout-3': 'mobile-desktop',
      'layout-4': 'tablet-desktop',
    };

    const devicesContainer = document.querySelector('#devices');
    if (devicesContainer) {
      devicesContainer.className = layoutClassMap[layout];
    }
  }
}
