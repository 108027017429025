// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.animate {
  opacity: 0;
    transform: scale(0.25);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    animation: append-animate .6s ease-in-out forwards;
}

@keyframes append-animate {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}






/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRlbXBsYXRlLWl0ZW0tcGFnZS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7O0FBRUE7RUFDRSxVQUFVO0lBQ1Isc0JBQXNCO0lBQ3RCLG1EQUFtRDtJQUNuRCxrREFBa0Q7QUFDdEQ7O0FBRUE7Q0FDQztFQUNDLG1CQUFtQjtFQUNuQixVQUFVO0NBQ1g7Q0FDQTtFQUNDLG1CQUFtQjtFQUNuQixVQUFVO0NBQ1g7QUFDRCIsImZpbGUiOiJ0ZW1wbGF0ZS1pdGVtLXBhZ2UuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuXG4uYW5pbWF0ZSB7XG4gIG9wYWNpdHk6IDA7XG4gICAgdHJhbnNmb3JtOiBzY2FsZSgwLjI1KTtcbiAgICB0cmFuc2l0aW9uOiBhbGwgMC42cyBjdWJpYy1iZXppZXIoMC4yMywgMSwgMC4zMiwgMSk7XG4gICAgYW5pbWF0aW9uOiBhcHBlbmQtYW5pbWF0ZSAuNnMgZWFzZS1pbi1vdXQgZm9yd2FyZHM7XG59XG5cbkBrZXlmcmFtZXMgYXBwZW5kLWFuaW1hdGUge1xuXHRmcm9tIHtcblx0XHR0cmFuc2Zvcm06IHNjYWxlKDApO1xuXHRcdG9wYWNpdHk6IDA7XG5cdH1cblx0dG8ge1xuXHRcdHRyYW5zZm9ybTogc2NhbGUoMSk7XG5cdFx0b3BhY2l0eTogMTtcblx0fVxufVxuXG5cblxuXG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/templates-layout/template-item/template-item-info-popup/template-item-page/template-item-page.component.css"],"names":[],"mappings":";;AAEA;EACE,UAAU;IACR,sBAAsB;IACtB,mDAAmD;IACnD,kDAAkD;AACtD;;AAEA;CACC;EACC,mBAAmB;EACnB,UAAU;CACX;CACA;EACC,mBAAmB;EACnB,UAAU;CACX;AACD;;;;;;;AAOA,o3BAAo3B","sourcesContent":["\n\n.animate {\n  opacity: 0;\n    transform: scale(0.25);\n    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);\n    animation: append-animate .6s ease-in-out forwards;\n}\n\n@keyframes append-animate {\n\tfrom {\n\t\ttransform: scale(0);\n\t\topacity: 0;\n\t}\n\tto {\n\t\ttransform: scale(1);\n\t\topacity: 1;\n\t}\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
