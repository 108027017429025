// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJlYXJuaW5ncy10ZWNoLmNvbXBvbmVudC5jc3MifQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/owner-earnings/earnings-tech/earnings-tech.component.css"],"names":[],"mappings":";;AAEA,4KAA4K","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
