// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-menu-link.active {
  background-color: var(--bs-primary-light) !important;
  color: var(--bs-primary) !important;
}


.responsive-grid-template {
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  /* grid-auto-rows: 80px; */
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1haW4tbmF2LWJhci5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0RBQW9EO0VBQ3BELG1DQUFtQztBQUNyQzs7O0FBR0E7RUFDRSwwREFBMEQ7RUFDMUQsMEJBQTBCO0FBQzVCIiwiZmlsZSI6Im1haW4tbmF2LWJhci5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnN1Yi1tZW51LWxpbmsuYWN0aXZlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tYnMtcHJpbWFyeS1saWdodCkgIWltcG9ydGFudDtcbiAgY29sb3I6IHZhcigtLWJzLXByaW1hcnkpICFpbXBvcnRhbnQ7XG59XG5cblxuLnJlc3BvbnNpdmUtZ3JpZC10ZW1wbGF0ZSB7XG4gIGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KGF1dG8tZml0LCBtaW5tYXgoODBweCwgMWZyKSk7XG4gIC8qIGdyaWQtYXV0by1yb3dzOiA4MHB4OyAqL1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/owner-dashboard/main-nav-bar/main-nav-bar.component.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,mCAAmC;AACrC;;;AAGA;EACE,0DAA0D;EAC1D,0BAA0B;AAC5B;;AAEA,gqBAAgqB","sourcesContent":[".sub-menu-link.active {\n  background-color: var(--bs-primary-light) !important;\n  color: var(--bs-primary) !important;\n}\n\n\n.responsive-grid-template {\n  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));\n  /* grid-auto-rows: 80px; */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
