// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .template-container {
  position: relative;
  border-radius: 22px;
}

.template-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 22px 22px 0 0;
}

.template-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 22px;
  background-size: cover;
  background-position: top;


} */


.preview-image-50px {
  max-width: 50px;
  max-height: 62px;
  overflow: hidden;
  border-radius: 15px;
}
.preview-image-50px img {
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZm9ybWF0aXZlLXNlY3Rpb24uY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7O0dBcUJHOzs7QUFHSDtFQUNFLGVBQWU7RUFDZixnQkFBZ0I7RUFDaEIsZ0JBQWdCO0VBQ2hCLG1CQUFtQjtBQUNyQjtBQUNBO0VBQ0UsV0FBVztBQUNiIiwiZmlsZSI6ImluZm9ybWF0aXZlLXNlY3Rpb24uY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi8qIC50ZW1wbGF0ZS1jb250YWluZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGJvcmRlci1yYWRpdXM6IDIycHg7XG59XG5cbi50ZW1wbGF0ZS1pbmZvIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICBib3R0b206IDA7XG4gIHdpZHRoOiAxMDAlO1xuICBib3JkZXItcmFkaXVzOiAyMnB4IDIycHggMCAwO1xufVxuXG4udGVtcGxhdGUtaW1hZ2Uge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHdpZHRoOiAxMDAlO1xuICBwYWRkaW5nLWJvdHRvbTogMTAwJTtcbiAgYm9yZGVyLXJhZGl1czogMjJweDtcbiAgYmFja2dyb3VuZC1zaXplOiBjb3ZlcjtcbiAgYmFja2dyb3VuZC1wb3NpdGlvbjogdG9wO1xuXG5cbn0gKi9cblxuXG4ucHJldmlldy1pbWFnZS01MHB4IHtcbiAgbWF4LXdpZHRoOiA1MHB4O1xuICBtYXgtaGVpZ2h0OiA2MnB4O1xuICBvdmVyZmxvdzogaGlkZGVuO1xuICBib3JkZXItcmFkaXVzOiAxNXB4O1xufVxuLnByZXZpZXctaW1hZ2UtNTBweCBpbWcge1xuICB3aWR0aDogMTAwJTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/wizard/informative-section/informative-section.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;GAqBG;;;AAGH;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;;AAEA,4kCAA4kC","sourcesContent":["/* .template-container {\n  position: relative;\n  border-radius: 22px;\n}\n\n.template-info {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  border-radius: 22px 22px 0 0;\n}\n\n.template-image {\n  position: relative;\n  width: 100%;\n  padding-bottom: 100%;\n  border-radius: 22px;\n  background-size: cover;\n  background-position: top;\n\n\n} */\n\n\n.preview-image-50px {\n  max-width: 50px;\n  max-height: 62px;\n  overflow: hidden;\n  border-radius: 15px;\n}\n.preview-image-50px img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
