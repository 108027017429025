// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.announcements-area {
    transition: all 0.3s ease-in-out;
}
.announcements-area.focus {
    transform: translateY(-10px) scale(1.03);
    box-shadow: var(--bs-box-shadow);
    /* border: 1px solid var(--bs-primary); */
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLGdDQUFnQztBQUNwQztBQUNBO0lBQ0ksd0NBQXdDO0lBQ3hDLGdDQUFnQztJQUNoQyx5Q0FBeUM7QUFDN0MiLCJmaWxlIjoicHJvZmlsZS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFubm91bmNlbWVudHMtYXJlYSB7XG4gICAgdHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZS1pbi1vdXQ7XG59XG4uYW5ub3VuY2VtZW50cy1hcmVhLmZvY3VzIHtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoLTEwcHgpIHNjYWxlKDEuMDMpO1xuICAgIGJveC1zaGFkb3c6IHZhcigtLWJzLWJveC1zaGFkb3cpO1xuICAgIC8qIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLWJzLXByaW1hcnkpOyAqL1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/profile/profile-main-page/profile.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA;IACI,wCAAwC;IACxC,gCAAgC;IAChC,yCAAyC;AAC7C;AACA,gnBAAgnB","sourcesContent":[".announcements-area {\n    transition: all 0.3s ease-in-out;\n}\n.announcements-area.focus {\n    transform: translateY(-10px) scale(1.03);\n    box-shadow: var(--bs-box-shadow);\n    /* border: 1px solid var(--bs-primary); */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
