// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}

.ip-table tbody td {
  padding: 0.4em 0.75em !important;
}

.ip-table th {
  padding: 1em 0.75em !important;
}



.stick-this-to-top {
  position: sticky;
  top: 0;
  z-index: 1;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFubm91bmNlbWVudC1jcmVhdGUtcG9wdXAuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0Usb0NBQW9DO0FBQ3RDOztBQUVBO0VBQ0UsZ0NBQWdDO0FBQ2xDOztBQUVBO0VBQ0UsOEJBQThCO0FBQ2hDOzs7O0FBSUE7RUFDRSxnQkFBZ0I7RUFDaEIsTUFBTTtFQUNOLFVBQVU7QUFDWiIsImZpbGUiOiJhbm5vdW5jZW1lbnQtY3JlYXRlLXBvcHVwLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcblxuLmlwLXN3aXRjaC1maXgge1xuICBtYXJnaW4tdG9wOjAgIWltcG9ydGFudDtcbn1cblxuLnN3aXRjaCBpbnB1dDpkaXNhYmxlZCB+IHNwYW46YmVmb3JlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2RhZGFkYSAhaW1wb3J0YW50O1xufVxuXG4uaXAtdGFibGUgdGJvZHkgdGQge1xuICBwYWRkaW5nOiAwLjRlbSAwLjc1ZW0gIWltcG9ydGFudDtcbn1cblxuLmlwLXRhYmxlIHRoIHtcbiAgcGFkZGluZzogMWVtIDAuNzVlbSAhaW1wb3J0YW50O1xufVxuXG5cblxuLnN0aWNrLXRoaXMtdG8tdG9wIHtcbiAgcG9zaXRpb246IHN0aWNreTtcbiAgdG9wOiAwO1xuICB6LWluZGV4OiAxO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/affiliate/owner-announcements/announcement-create-popup/announcement-create-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;;;AAIA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA,44BAA44B","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n.ip-table tbody td {\n  padding: 0.4em 0.75em !important;\n}\n\n.ip-table th {\n  padding: 1em 0.75em !important;\n}\n\n\n\n.stick-this-to-top {\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
