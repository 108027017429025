// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-card.selectable {
    cursor: pointer;
}

.payment-card {
    border: 1px solid var(--bs-secondary);
}
.payment-card.selectable.selected {
    opacity: 1;
    border: 1px solid var(--bs-primary);
    background-color: var(--bs-primary-light) !important;
}
.payment-card.selectable:not(.selected) {
    opacity: 0.5;
}

.payment-card.selectable:not(.selected):hover {
    opacity: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBheW1lbnQtbWV0aG9kLWl0ZW0uY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLGVBQWU7QUFDbkI7O0FBRUE7SUFDSSxxQ0FBcUM7QUFDekM7QUFDQTtJQUNJLFVBQVU7SUFDVixtQ0FBbUM7SUFDbkMsb0RBQW9EO0FBQ3hEO0FBQ0E7SUFDSSxZQUFZO0FBQ2hCOztBQUVBO0lBQ0ksVUFBVTtBQUNkIiwiZmlsZSI6InBheW1lbnQtbWV0aG9kLWl0ZW0uY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wYXltZW50LWNhcmQuc2VsZWN0YWJsZSB7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xufVxuXG4ucGF5bWVudC1jYXJkIHtcbiAgICBib3JkZXI6IDFweCBzb2xpZCB2YXIoLS1icy1zZWNvbmRhcnkpO1xufVxuLnBheW1lbnQtY2FyZC5zZWxlY3RhYmxlLnNlbGVjdGVkIHtcbiAgICBvcGFjaXR5OiAxO1xuICAgIGJvcmRlcjogMXB4IHNvbGlkIHZhcigtLWJzLXByaW1hcnkpO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLXByaW1hcnktbGlnaHQpICFpbXBvcnRhbnQ7XG59XG4ucGF5bWVudC1jYXJkLnNlbGVjdGFibGU6bm90KC5zZWxlY3RlZCkge1xuICAgIG9wYWNpdHk6IDAuNTtcbn1cblxuLnBheW1lbnQtY2FyZC5zZWxlY3RhYmxlOm5vdCguc2VsZWN0ZWQpOmhvdmVyIHtcbiAgICBvcGFjaXR5OiAxO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/profile/payments/payment-method-item/payment-method-item.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,qCAAqC;AACzC;AACA;IACI,UAAU;IACV,mCAAmC;IACnC,oDAAoD;AACxD;AACA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;AACA,o9BAAo9B","sourcesContent":[".payment-card.selectable {\n    cursor: pointer;\n}\n\n.payment-card {\n    border: 1px solid var(--bs-secondary);\n}\n.payment-card.selectable.selected {\n    opacity: 1;\n    border: 1px solid var(--bs-primary);\n    background-color: var(--bs-primary-light) !important;\n}\n.payment-card.selectable:not(.selected) {\n    opacity: 0.5;\n}\n\n.payment-card.selectable:not(.selected):hover {\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
