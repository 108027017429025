// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRocmVhZC11c2Vycy1saXN0LXBvcHVwLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6Ijs7QUFFQTtFQUNFLHVCQUF1QjtBQUN6QiIsImZpbGUiOiJ0aHJlYWQtdXNlcnMtbGlzdC1wb3B1cC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG5cbi5pcC1zd2l0Y2gtZml4IHtcbiAgbWFyZ2luLXRvcDowICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/messenger/thread-users-list-popup/thread-users-list-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA,gXAAgX","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
