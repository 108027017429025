import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Chat, Message } from "../../services/chat.service";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "app-chat-area",
  templateUrl: "./chat-area.component.html",
  styleUrls: ["./chat-area.component.css"],
})
export class ChatAreaComponent {
  @Input() chat: Chat;
  @Input() layout: "chat-bubbles" | "centered" = "chat-bubbles";
  @Output() openViewer = new EventEmitter<string>();
  @Output() messageEdited = new EventEmitter<Message>();
  @ViewChild("msgcontainer") chatArea!: ElementRef;

  //@ViewChild("chatArea") private chatArea!: ElementRef;
  constructor(private storageService: StorageService) {
    this.chat = {
      id: "",
      title: "",
      deactivated: false,
      date: new Date(),
      messages: [],
    };
  }

  ngAfterViewChecked() {
    let stickyScroll = this.storageService.getItem<boolean>("stickyScroll");
    this.scrollToBottom();
  }

  ngAfterViewInit() {
    this.chatArea.nativeElement.parentElement.addEventListener("scroll", () => {
      const div = this.chatArea.nativeElement.parentElement;
      if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
        //
        this.storageService.setItem("stickyScroll", true);
      } else {
        //
        this.storageService.setItem("stickyScroll", false);
      }
    });
  }

  scrollToBottom() {
    let stickyScroll = this.storageService.getItem<boolean>("stickyScroll");
    if (stickyScroll) {
      this.chatArea.nativeElement.parentElement.scrollTop =
        this.chatArea.nativeElement.parentElement.scrollHeight;
    }
  }

  handleMessageEdit(updatedMessage: Message): void {
    this.messageEdited.emit(updatedMessage);
  }
}
