import { ApiService } from 'src/app/shared/services';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OfferItem } from 'src/app/components/user-dashboard/offer/models/offer';
import { OfferStausEnum } from 'src/app/components/user-dashboard/offer/models/offer';
import { SharedOfferService } from 'src/app/components/user-dashboard/offer/shared-offer.service';
import { CourseHierarchyItem, CourseHierarchyItemTypeEnum } from 'src/app/components/user-dashboard/product/models/course';
import { PageItem, PageItemStatusEnum } from 'src/app/models/portal';

import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { updateWebsitePage } from 'src/app/shared/store/_features/website/website.actions';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-dynamic-page-status',
  templateUrl: './dynamic-page-status.component.html',
  styleUrls: ['./dynamic-page-status.component.css']
})
export class DynamicPageStatusComponent implements OnInit {
  loaded: boolean = true;
  openDripModal: boolean = false;
  openLockByModal: boolean = false;


  courseHierarchyItemTypeEnum = CourseHierarchyItemTypeEnum
  pageStausEnum = PageItemStatusEnum;
  @Input() item: PageItem;


  @Output() onDone = new EventEmitter<any>()
  dropIsActive: boolean = false;
  constructor(private toastr: ToastrService, public api: ApiService, private store: Store) { }


  onChangeOpen(event: any) {
    this.dropIsActive = event;

  }
  ngOnInit() {
    // this.item.status = PageItemStatusEnum.system
    // this.item.status = CourseItemStatus.PUBLISHED
  }

  async publishItem() {
    this.changeStatus(PageItemStatusEnum.published);
  }

  async draftItem() {
    this.changeStatus(PageItemStatusEnum.draft);
    // await this.editOffer(offer.id);
    // this.sharedOfferService.changeOfferStatus(true, offer, 'status');
  }





  async changeStatus(status: PageItemStatusEnum) {

    // this.courseViewService.courseBuilderLoading.loading = true;
    this.loaded = false;
    // await this.sharedOfferService.changeOfferStatus(this.item, status);
    let request$ = this.api.changePageStatus(this.item.id, status, this.changePageStatusErrorHandler);
    let response = await lastValueFrom(request$);
    
    const updatedPage = {...this.item , status:status};
    this.item = updatedPage;

    // this.item.status = status
    this.store.dispatch(updateWebsitePage({ page: this.item }));

    // this.onDone.emit();
    this.toastr.success(`Successfully updated the Page ${this.item.title} to be ${status}!`);
    this.loaded = true;
    // this.courseViewService.courseBuilderLoading.loading = false;
  }

  changePageStatusErrorHandler = (error: any) => {
    this.loaded = true;
    this.toastr.error(error.message);
  }


  async deleteItem() {
    // this.courseViewService.deleteCourseHeirarchyItem(this.courseId, this.item);
  }


}
