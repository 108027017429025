import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-learmo-logo',
  templateUrl: './learmo-logo.component.html',
})
export class LearmoLogoComponent {
  _normalLogoURL = 'assets/images/logo/learmo.png';
  _lightLogoURL = 'assets/images/logo/learmo-light.png';
  overlay: 'normal' | 'white' = 'normal';
  type: 'icon' | 'logo' = 'logo';

  @Input() classes: string = 'h-[35px] lg:h-[45px]'; // Tailwind classes for height

  @Input('type') set logoType(value: 'icon' | 'logo') {
    this.type = value;
    switch (value) {
      case 'icon':
        this._normalLogoURL = 'assets/images/logo/learmo-icon.png';
        this._lightLogoURL = 'assets/images/logo/learmo-icon-light.png';
        break;
      case 'logo':
        this._normalLogoURL = 'assets/images/logo/learmo.png';
        this._lightLogoURL = 'assets/images/logo/learmo-light.png';
        break;
      default:
        break;
    }
  }

  @Input('overlay') set logoOverlay(value: 'normal' | 'white') {
    this.overlay = value;
  }
}