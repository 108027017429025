import { WebsiteModel } from '../../../../portal.model';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  UntypedFormBuilder,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';

import {
  AffiliateLinkMinimizedModel,
  AffiliateMinimizedModel,
} from '../../../../../../../models/owner-dashboard';
import { lastValueFrom, takeWhile } from 'rxjs';
import { ApiService } from '../../../../../../../shared/services/lsg.api.service';
import { AffiliatesInvitationStatusEnum } from '../../../../../../../models/owner-dashboard';
import { emailRegex } from '../../../../../../../shared/services/regex';
import { PageItem } from 'src/app/models/portal';
import { UserWebsite } from '../../../../portal.model';
import Swal from 'sweetalert2';
import { SharedPortalViewService } from '../../../shared-portal-view.service';
import { Store } from '@ngrx/store';
import { addNewWebsitePage } from 'src/app/shared/store/_features/website/website.actions';
import { updateWebsiteBlogPostActions, WebsiteBlogCreateActions } from 'src/app/shared/store/_features/website/blog/website-blog.actions';
import { CreateOrUpdateBlogPostDTO } from './dto/create-blog.dto';
import { CustomNgbModalService } from 'src/app/shared/services/custom-ngbmodal.service';
import { selectBlogPostProcessStatus, selectWebsiteBlogState } from 'src/app/shared/store/_features/website/blog/website-blog.selector';
import LearmoEditorJS from 'src/app/shared/partials/learmo-block-editor/utils';
import { DropdownItem } from 'src/app/shared/partials/dynamic-dropdown/dynamic-dropdown.component';
import { CtaMessageItem } from 'src/app/shared/partials/ai/ai-cta-card/ai-cta-card.component';
import { colorSet } from 'src/app/models/global-classes';
import { AiGeneratorComponentData } from 'src/app/shared/partials/ai/ai-idea-generator/ai-generator.models';
import {
  MediaLibraryCategoryEnum,
  MediaLibraryUploadType,
} from 'src/app/models/sales-funnel';
import { BlogPostItem } from 'src/app/models/blog.model';

export enum InvitationCurrentStepEnum {
  input = 'input',
  result = 'result',
}

export enum PostAiAction {
  generate_content = 'generate_content',
}

@Component({
  selector: 'app-create-blog-post-popup',
  templateUrl: './create-blog-post-popup.component.html',
  styleUrls: ['./create-blog-post-popup.component.css'],
})
export class CreateBlogPostPopupComponent implements OnInit {
  usingAi: boolean = false;
  createNewPost: boolean = true;
  temp: { [key: string]: any } = new Object();
  editorController: LearmoEditorJS;
  dropdownItems: DropdownItem[] = [];
  mediaLibraryCategoryEnum = MediaLibraryCategoryEnum;
  mediaLibraryUploadType = MediaLibraryUploadType;
  ctaData: { colorSet: colorSet; image: string; message: CtaMessageItem } = {
    colorSet: 'primary',
    image: 'assets/images/illustrations/brain-question.svg',
    message: {
      main: { value: 'Feeling out of ideas?' },
      sub: { value: "No need to worry; I'm here to assist you." },
    },
  };

  postForm = new FormGroup({
    title: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    content: new FormControl(null),
    status: new FormControl(null),
    imageId: new FormControl([], null),
    image: new FormControl([], null),
  });

  processing: 'none' | 'loading' = 'none';
  @ViewChild('postModal') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;
  @Input() website: WebsiteModel;

  post: BlogPostItem;
  posts: BlogPostItem[] = [];

  constructor(
    private modalService: CustomNgbModalService,
    private route: Router,
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public sharedPortalViewService: SharedPortalViewService,
    private store: Store,
   ) {}

  async ngOnInit() {
    this.assignDropdownActions();
    this.postForm.valueChanges.subscribe((value) => {
      
    });
  }

  startProcessing() {
    this.processing = 'loading';
  }

  stopProcessing() {
    this.processing = 'none';
  }

  async createOrUpdatePost(payload?: CreateOrUpdateBlogPostDTO) {
    this.postForm.markAllAsTouched();

    if (this.postForm.valid) {
      payload = {
        title: this.postForm.value.title,
        content: this.postForm.value.content,
        status: this.postForm.value.status,
        imageId: this.postForm.value.image?.map((img) => img.id),
      };

      this.startProcessing();

      if (this.createNewPost) {
        this.createBlogPost(payload)
      } else {
        this.updateBlogPost(payload);
      }
    }
  }




  createBlogPost(payload?: CreateOrUpdateBlogPostDTO) {
    this.store.dispatch(WebsiteBlogCreateActions.create({ payload }));
    this.store.select(selectBlogPostProcessStatus).pipe(takeWhile(process => process.status == 'creating', true)).subscribe(process => {
      if (process.status === 'done' && process?.error?.processType != 'creating') {
        this.onCreateDone();
      }

      if (process.status === 'error' && process?.error?.processType == 'creating') {
        this.onCreateError(process.error.error);
      }
    });
  }

  onCreateDone(payload?: CreateOrUpdateBlogPostDTO) {
    this.closeModal();
    this.toastr.success('Done!', 'Post Created Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  onCreateError(error) {
    this.toastr.error('Error!', `${error}`, {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }


  updateBlogPost(payload?: CreateOrUpdateBlogPostDTO) {
      this.store.dispatch(updateWebsiteBlogPostActions.update({ postId: this.post.id, changes: payload }));
      this.store.select(selectBlogPostProcessStatus).pipe(takeWhile(process => process.status == 'updating', true)).subscribe(process => {
        if (process.status === 'done' && process?.error?.processType != 'updating') {
          this.onUpdateDone();
        }

        if (process.status === 'error' && process?.error?.processType == 'updating') {
          this.onUpdateError(process.error.error);

        }
      });
  }

  onUpdateDone(payload?: CreateOrUpdateBlogPostDTO) {
    this.closeModal();
    this.toastr.success('Done!', 'Post Updated Successfully', {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }

  onUpdateError(error) {
    this.toastr.error('Error!', `${error}`, {
      closeButton: false,
      positionClass: 'toast-bottom-right',
    });
  }


  resetModal() {
    this.stopProcessing();
    this.postForm.reset();
  }

  closeModal() {
    this.modalRef.dismiss();
    this.resetModal();
  }

  async open(content?) {
    this.resetModal();

    this.createNewPost = true;

    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      fullscreen: true,
      animation: false,
      backdrop: true,
      // beforeDismiss: ()=> {
      //   return false
      // },
      windowClass:
        'modal-animate-in lesson-item-modal learmo-full-screen-modal',
    });
  }

  openEditModal(post: BlogPostItem) {
    this.post = post;
    this.postForm.patchValue({
      title: post.title || '',
      content: post.content || '',
      image: post.image || null,
      imageId: post.imageId || null,
    });
    this.createNewPost = false;
    this.modalRef = this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      fullscreen: true,
      animation: false,
      backdrop: true,
      // beforeDismiss: ()=> {
      //   return false
      // },
      windowClass:
        'modal-animate-in lesson-item-modal learmo-full-screen-modal',
    });
  }

  onGetResult(result: string) {
    this.postForm.controls.title.setValue(result);
    this.usingAi = false;
  }

  onAiDataChange(event: AiGeneratorComponentData) {
    
    this.temp.titleAiState = event;
  }

  cancelClicked() {

    this.usingAi = false;
  }

  titlesPromptFunction = () => {
    return `Generate five modern fancy creative Blog Articles titles ideas`;
  };

  onCatchController(controller: LearmoEditorJS) {
    this.editorController = controller;
  }
  assignDropdownActions() {
    this.dropdownItems = [
      {
        label: 'Generate Content',
        colorSet: 'primary',
        colorSetHover: 'primary',
        iconSvg: 'assets/images/svg/system/ai-tasks/grammar-correction.svg',
        action: () => {
          this.onAction(PostAiAction.generate_content);
        },
      },
      {
        label: 'Summarize & shorten',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        iconSvg: 'assets/images/svg/system/ai-tasks/summarize.svg',
        disabled: true,
      },
      {
        label: 'Rewrite & paraphrase',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        iconSvg: 'assets/images/svg/system/ai-tasks/rephrase.svg',
        disabled: true,
      },
      {
        label: 'Create AI-Powered Image',
        colorSet: 'gray-700',
        colorSetHover: 'primary',
        iconSvg: 'assets/images/svg/system/ai-tasks/generate-image.svg',
        disabled: true,
      },
    ];
  }

  onAction(action: PostAiAction) {
    switch (action) {
      case PostAiAction.generate_content:
        this.generateContent();
        break;

      default:
        break;
    }
  }

  generateContent() {
    if (!!this.postForm.value.title) {
      this.editorController.startGenerating();
    }
  }

  promptFunction = (
    postTitle: string = this.postForm.value.title,
    content: string = this.postForm.value.content
  ) => {
    let suggestedContent = !!content?.length
      ? `inspire from this content '${content}'`
      : '';

    return `Generate a html start with <body> formatted long blog article with the title '${postTitle}' ${suggestedContent} **use html for bullets and lists**:`;
  };




}


