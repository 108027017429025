import { NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInOut } from '../../../animations/animations';
import { SharedOwnerDashboardService } from 'src/app/shared/layout/owner-dashboard/shared-owner-dashboard.service';
import { Component, Input, OnInit, Renderer2, ElementRef } from '@angular/core';
import { NavigationMenuItem } from 'src/app/models/owner-dashboard';
import { UserDashboardService } from 'src/app/shared/services/user-dashboard.service';

@Component({
  selector: 'app-main-nav-bar-list',
  templateUrl: './main-nav-bar-list.component.html',
  styleUrls: ['./main-nav-bar-list.component.css'],
  animations: [slideInOut],
})
export class MainNavBarListComponent implements OnInit {
  processing: boolean = false;
  @Input() sideMenu: NavigationMenuItem[];
  @Input() isMobile: boolean;
  public isCollapsed: boolean;

  @Input('isCollapsed') set setIsCollapsed(value: boolean) {
    

    // if (value == false) {
    // this.processing = true;
    
    // setTimeout(() => {
    //   this.processing = false;  
    // }, 500);  
    // }

    this.isCollapsed = value;
    
    
  }
  public hoverOnItem: NavigationMenuItem = null;

  public popoverTippyProps: NgxTippyProps = {
    placement: 'right',
    followCursor: false,
    trigger: 'mouseenter click',
    interactive: true,
    appendTo: document.body,
  };
  constructor(
    public sharedOwnerDashboardService: SharedOwnerDashboardService,
    public router: Router,
    public userDashboardService: UserDashboardService,
    private ngxTippyService: NgxTippyService
  ) {}

  public getTooltipText(item: any): string | null {
    if (
      item?.subItems?.length &&
      (this.isCollapsed || (!item.expandable?.status && this.isCollapsed))
    ) {
      return item.label;
    }
    return null;
  }

  public ngOnInit(): void {
    this.sideMenu.forEach((item) => {
      if (!!item.subItems?.length) {
        const routerActiveArray = item.subItems.forEach((sub) => {
          if (this.router.url.includes(sub.routerLink)) {
            this.sharedOwnerDashboardService.expandSidebarItem(
              this.sideMenu,
              item
            );
          }
        });
      }
    });
  }

  public expandSidebarItem(sideMenu: NavigationMenuItem[], item: NavigationMenuItem) {
    this.sharedOwnerDashboardService.expandSidebarItem(sideMenu, item);
  }
  public collapseSidebarItem(item: NavigationMenuItem) {
    this.sharedOwnerDashboardService.collapseSidebarItem(item);
  }
  public toggleSidebarItem(
    sideMenu: NavigationMenuItem[],
    item: NavigationMenuItem,
    forceExpand = false
  ) {
    if (!!item?.subItems?.length) {
      if (!!item?.expandable?.status && !forceExpand) {
        this.collapseSidebarItem(item);
      } else {
        this.expandSidebarItem(sideMenu, item);
      }
    } else {
      this.expandSidebarItem(sideMenu, item);
    }

    if (!!item?.routerLink) {
      this.userDashboardService.closeMobileCollapse();
    }
  }

  public showMenuOnHover(item: any) {
    if (item.subItems && item.subItems.length && !item.expandable.status) {
      return item.subItems.map((sub) => sub.label).join('<br>');
    }
    return item.label;
  }
}
