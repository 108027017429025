// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-vip {
  background-color: #F5DDF8 !important;
  color: #55255F !important;
}

.svg-vip {
  fill: #55255F !important;
}

.label-private {
  background-color: #DDE8F8 !important;
  color: #25475F !important;
}

.svg-private {
  fill: #25475F !important;
}

.label-public {
  background-color: #DDF8E2 !important;
  color: #265F25 !important;
}

.svg-public {
  fill: #265F25 !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFmaWxpYXRlLWxpbmstdHlwZS1sYWJlbC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0NBQW9DO0VBQ3BDLHlCQUF5QjtBQUMzQjs7QUFFQTtFQUNFLHdCQUF3QjtBQUMxQjs7QUFFQTtFQUNFLG9DQUFvQztFQUNwQyx5QkFBeUI7QUFDM0I7O0FBRUE7RUFDRSx3QkFBd0I7QUFDMUI7O0FBRUE7RUFDRSxvQ0FBb0M7RUFDcEMseUJBQXlCO0FBQzNCOztBQUVBO0VBQ0Usd0JBQXdCO0FBQzFCIiwiZmlsZSI6ImFmaWxpYXRlLWxpbmstdHlwZS1sYWJlbC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxhYmVsLXZpcCB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGNURERjggIWltcG9ydGFudDtcbiAgY29sb3I6ICM1NTI1NUYgIWltcG9ydGFudDtcbn1cblxuLnN2Zy12aXAge1xuICBmaWxsOiAjNTUyNTVGICFpbXBvcnRhbnQ7XG59XG5cbi5sYWJlbC1wcml2YXRlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI0RERThGOCAhaW1wb3J0YW50O1xuICBjb2xvcjogIzI1NDc1RiAhaW1wb3J0YW50O1xufVxuXG4uc3ZnLXByaXZhdGUge1xuICBmaWxsOiAjMjU0NzVGICFpbXBvcnRhbnQ7XG59XG5cbi5sYWJlbC1wdWJsaWMge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjRERGOEUyICFpbXBvcnRhbnQ7XG4gIGNvbG9yOiAjMjY1RjI1ICFpbXBvcnRhbnQ7XG59XG5cbi5zdmctcHVibGljIHtcbiAgZmlsbDogIzI2NUYyNSAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/affiliate/afiliate-links/afiliate-link-type-label/afiliate-link-type-label.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA,4iCAA4iC","sourcesContent":[".label-vip {\n  background-color: #F5DDF8 !important;\n  color: #55255F !important;\n}\n\n.svg-vip {\n  fill: #55255F !important;\n}\n\n.label-private {\n  background-color: #DDE8F8 !important;\n  color: #25475F !important;\n}\n\n.svg-private {\n  fill: #25475F !important;\n}\n\n.label-public {\n  background-color: #DDF8E2 !important;\n  color: #265F25 !important;\n}\n\n.svg-public {\n  fill: #265F25 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
