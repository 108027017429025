// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .user-empty-interactions {
  min-height: 300px;
  border: 2px dashed #e5e5e5;
  border-radius: 10px;
}

.empty_state {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.empty_state i {
  margin: auto;
  margin-bottom: 0px;
  font-size: 90px;
  color: #ccc;
}
.empty_state h3 {
  margin: 8px 0px;
  text-align: center;
  font-weight: normal;
}
.empty_state p {
  font-size: 14px;
  margin: 0px;
  color: #999;
  text-align: center;
} */

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vLXJlc3VsdHMtc2VhcmNoLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7O0dBNkJHIiwiZmlsZSI6Im5vLXJlc3VsdHMtc2VhcmNoLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiAudXNlci1lbXB0eS1pbnRlcmFjdGlvbnMge1xuICBtaW4taGVpZ2h0OiAzMDBweDtcbiAgYm9yZGVyOiAycHggZGFzaGVkICNlNWU1ZTU7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG59XG5cbi5lbXB0eV9zdGF0ZSB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogMTAwJTtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbn1cbi5lbXB0eV9zdGF0ZSBpIHtcbiAgbWFyZ2luOiBhdXRvO1xuICBtYXJnaW4tYm90dG9tOiAwcHg7XG4gIGZvbnQtc2l6ZTogOTBweDtcbiAgY29sb3I6ICNjY2M7XG59XG4uZW1wdHlfc3RhdGUgaDMge1xuICBtYXJnaW46IDhweCAwcHg7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgZm9udC13ZWlnaHQ6IG5vcm1hbDtcbn1cbi5lbXB0eV9zdGF0ZSBwIHtcbiAgZm9udC1zaXplOiAxNHB4O1xuICBtYXJnaW46IDBweDtcbiAgY29sb3I6ICM5OTk7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbn0gKi9cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/main-lists/misc/no-results-search/no-results-search.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA6BG;;AAEH,g+BAAg+B","sourcesContent":["/* .user-empty-interactions {\n  min-height: 300px;\n  border: 2px dashed #e5e5e5;\n  border-radius: 10px;\n}\n\n.empty_state {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.empty_state i {\n  margin: auto;\n  margin-bottom: 0px;\n  font-size: 90px;\n  color: #ccc;\n}\n.empty_state h3 {\n  margin: 8px 0px;\n  text-align: center;\n  font-weight: normal;\n}\n.empty_state p {\n  font-size: 14px;\n  margin: 0px;\n  color: #999;\n  text-align: center;\n} */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
