// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-type-container .back-button {
  width: 0;
  height: 40px;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}

.contact-type-container:hover .back-button {
  width: 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyZWF0ZS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsUUFBUTtFQUNSLFlBQVk7RUFDWixnQkFBZ0I7RUFDaEIsa0NBQWtDO0FBQ3BDOztBQUVBO0VBQ0UsV0FBVztBQUNiIiwiZmlsZSI6ImNyZWF0ZS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbnRhY3QtdHlwZS1jb250YWluZXIgLmJhY2stYnV0dG9uIHtcbiAgd2lkdGg6IDA7XG4gIGhlaWdodDogNDBweDtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgdHJhbnNpdGlvbjogd2lkdGggMC4zcyBlYXNlLWluLW91dDtcbn1cblxuLmNvbnRhY3QtdHlwZS1jb250YWluZXI6aG92ZXIgLmJhY2stYnV0dG9uIHtcbiAgd2lkdGg6IDQwcHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/contacts/create/create.component.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;AACA,wkBAAwkB","sourcesContent":[".contact-type-container .back-button {\n  width: 0;\n  height: 40px;\n  overflow: hidden;\n  transition: width 0.3s ease-in-out;\n}\n\n.contact-type-container:hover .back-button {\n  width: 40px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
