import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { loadCustomScript } from '@paypal/paypal-js';
import { lastValueFrom } from 'rxjs';
import { PaymentGatewaysIntegration, PaypalPaymentGatewayModel } from 'src/app/models/portal';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-paypal-integration-from',
  templateUrl: './paypal-integration-from.component.html',
  styleUrls: ['./paypal-integration-from.component.css'],
})
export class PaypalIntegrationFromComponent implements OnInit, AfterViewInit {
  isError: string;
  paypalStorageKey = environment.storageKeys.paypalIntegration;
  processing = false;
  integratePaymentForm: FormGroup = new FormGroup({
    emailAddress: new FormControl('', {
      validators: [Validators.required],
    }),
    clientId: new FormControl('', {
      validators: [Validators.required],
    }),
    secretKey: new FormControl('', {
      validators: [Validators.required],
    }),
    merchantId: new FormControl('', {
      validators: [Validators.required],
    }),
  });


  // Auto Payment Button
  paypalInitialized: boolean = false;
  integrationUrl: string;
  nonce: string;
  constructor(private api: ApiService, private toastr: ToastrService) {
    this.integratePaymentForm.valueChanges.subscribe((a) => {
      this.isError = null;
    });

    window['onboardedCallback'] = this.onboardedCallback.bind(this);
  }
  ngAfterViewInit(): void {
    this.initializePaypalIntegration();
  }

  ngOnInit() {
    
  }



  async submitForm() {
    this.integratePaymentForm.markAllAsTouched();
    if (!this.integratePaymentForm.valid) {
      return false;
    }

    let payload:PaypalPaymentGatewayModel = {
      emailAddress: this.integratePaymentForm.get('emailAddress').value,
      clientId: this.integratePaymentForm.get('clientId').value,
      secretKey: this.integratePaymentForm.get('secretKey').value,
      merchantId: this.integratePaymentForm.get('merchantId').value
    }

    await this.integrationRequest({ payPal: payload });
  }
  
  async integrationRequest(payload: PaymentGatewaysIntegration) {
    this.processing = true;
    this.integratePaymentForm.disable();
    const request$ = this.api.integratePaymentGateways(
      payload,
      this.onError
    );
    const response: { success: boolean } = await lastValueFrom(request$);
    if (response.success) {
      this.onSuccess();
    }
  }
  

  async onSuccess() {

    // await this.sharedPortalViewService.goToPayments();
    this.toastr.success(
      `Paypal payment gateway integrated successfully`,
      'Done!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );
    // await this.sharedPortalViewService.getPaymentMethods();
    setTimeout(() => {
      this.processing = false;
    }, 500);
    this.integratePaymentForm.enable();
  }

  onError = (error: any) => {
    this.integratePaymentForm.enable();
    this.processing = false;
    // this.startEdited('offer', 'edited');
    this.toastr.error(
      `Paypal payment gateway doesn't integrated`,
      'Error!',
      {
        closeButton: false,
        positionClass: 'toast-bottom-right',
      }
    );

    this.isError = error?.message;
  };

  // Auto Payment Functions
  async loadPaypalScripts() {
    // await loadCustomScript({
    //   url: 'https://www.paypal.com/sdk/js?client-id=ASPTSbkQnaFbGO5KQxd_Y9fRWlfRW2zC2xUl7vBWxsE-6mwgmur6sw31Oq_gcvckhWD1CCgISlB9UMoY&components=buttons',
    //   attributes: {
    //     id: 'paypal-partner-js',
    //   },

    // });
    // return await loadCustomScript({
    //   url: 'https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js',
    //   attributes: {
    //     id: 'paypal-partner-js',
    //   },
    // });
    return await loadCustomScript({
      url: 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js',
      attributes: {
        id: 'paypal-partner-js',
      },
    });
  }
  async initializePaypalIntegration() {
    this.paypalInitialized = false;
    
      await this.loadPaypalScripts();  
    
    

    // this.renderer.setAttribute(this.existingButton.nativeElement, 'data-paypal-onboard-complete', 'onboardedCallback');
    // 

    let request$ = this.api.generatePaypalIntegrationReferral({
      retrun_url: `${window.location.origin}${window.location.pathname}`,
    });
    let response: { url: string; nonce: string } = await lastValueFrom(
      request$
    );
    this.nonce = response.nonce;
    
    this.integrationUrl = response.url;
    this.paypalInitialized = true;
  }

  onboardedCallback = (authCode: string, sharedId: string) => {
    alert("Setting Paypal Storage Data")
    localStorage.setItem(
      this.paypalStorageKey,
      JSON.stringify({ authCode, sharedId, email: null, nonce: this.nonce })
    );

    
  }
}
