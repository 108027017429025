// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-list {
    max-height: 70vh;
  overflow: auto;
  min-height: fit-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm93bmVyLW5vdGlmaWNhdGlvbnMtbGlzdC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksZ0JBQWdCO0VBQ2xCLGNBQWM7RUFDZCx1QkFBdUI7QUFDekIiLCJmaWxlIjoib3duZXItbm90aWZpY2F0aW9ucy1saXN0LmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubm90aWZpY2F0aW9ucy1saXN0IHtcbiAgICBtYXgtaGVpZ2h0OiA3MHZoO1xuICBvdmVyZmxvdzogYXV0bztcbiAgbWluLWhlaWdodDogZml0LWNvbnRlbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/layout/top-menu/fast-actions-buttons/owner-notifications-list/owner-notifications-list.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB,cAAc;EACd,uBAAuB;AACzB;AACA,ocAAoc","sourcesContent":[".notifications-list {\n    max-height: 70vh;\n  overflow: auto;\n  min-height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
