import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StorageService } from './storage.service';
import { OpenAI } from 'openai';
import groupChatsByDate from './utils';
import { environment } from 'src/environments/environment';
import { AiService } from 'src/app/shared/services/ai.service';
import {
  ChatCompletionAssistantMessageParam,
  ChatCompletionMessageParam,
  ChatCompletionSystemMessageParam,
  ChatCompletionUserMessageParam,
} from 'openai/resources';

export enum ChatMessageRole {
  User = 'user',
  Assistant = 'bot',
  System = 'system',
}
export class Message {
  id: string;
  from: ChatMessageRole;
  type: 'text' | 'image';
  text: string;
  links?: Array<{ title: string; url: string }>;
  complete?: boolean;
  isHidden?: boolean;
  partToShow?: string;
  firstEmitIsHere?: boolean;

  constructor(payload: {
    id?: string;
    from?: ChatMessageRole;
    text?: string;
    type: 'text' | 'image';
    isHidden: boolean;
    partToShow?: string;
    complete?: boolean;
  }) {
    this.id = payload.id || Message.generateId();
    this.from = payload.from;
    this.text = payload.text;
    this.type = payload.type || 'text';
    this.isHidden = payload.isHidden || false;
    this.partToShow = payload.partToShow;
    this.complete = payload.complete;
  }

  static generateId(): string {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    const randomValue = crypto.getRandomValues(new Uint8Array(4)).join('');
    return `${timestamp}-${randomValue}`;
  }
}

export interface Chat {
  id: string;
  deactivated: boolean;
  title: string;
  messages: Message[];
  date: Date;
}

@Injectable({ providedIn: 'root' })
export class ChatService {


  currentChat: Chat = {
    id: '',
    deactivated: false,
    title: '',
    date: new Date(),
    messages: [],
  };

  chats: Chat[] = [];
  chatGroups: { title: string; chats: Chat[] }[] = [];

  // private currentChatSubject = new BehaviorSubject<Chat>(this.chats[0]);
  // currentChat = this.currentChatSubject.asObservable();

  private chatsVisibility = new BehaviorSubject(false); // set initial state to 'false'
  currentVisibility = this.chatsVisibility.asObservable();

  constructor(
    private storageService: StorageService,
    private aiService: AiService
  ) {
    // Try to load chats from local storage during service initialization
    const storedChats = this.storageService.getItem<Chat[]>('chats');
    if (storedChats) {
      this.refreshChats(storedChats);
      // this.currentChatSubject.next(this.chats[0]);
      this.switchChat(this.chats[0]);
    }
  }

  refreshChats(chats: Chat[] = this.chats) {
    this.chats = chats;
    this.chatGroups = groupChatsByDate(this.chats);
  }

  toggleChatsVisibility() {
    this.chatsVisibility.next(!this.chatsVisibility.value);
  }

  hideChatsVisibility() {
    this.chatsVisibility.next(false);
  }

  showChatsVisibility() {
    this.chatsVisibility.next(true);
  }

  getChats(): Observable<Chat[]> {
    return of(this.chats);
  }

  updateChatConfig(chatId: string, title?: string): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    if (!!title) {
      this.chats[chatIndex].title = title;
    }
    this.storageService.setItem('chats', this.chats);
  }

  switchChat(chat: Chat) {
    // this.currentChatSubject.next(chat);
    this.currentChat = chat;
    this.scrollToChatEnd();
  }

  selectChat(chat: Chat) {
    this.switchChat(chat);
    this.hideChatsVisibility();
  }

  setChatTitle(chatId: string, title: string): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }
    this.chats[chatIndex].title = title;
    this.storageService.setItem('chats', this.chats);
  }

  addChat(
    title: string,
    firstMessageBody: string,
    selectAfterCreate: boolean = true
  ): Chat {
    let newChat: Chat = {
      id: Message.generateId(), // Implement a method for generating unique id
      deactivated: false,
      title: title,
      date: new Date(),
      messages: [],
    };
    this.chats.unshift(newChat);
    this.storageService.setItem('chats', this.chats);

    this.refreshChats();
    if (selectAfterCreate) {
      this.selectChat(newChat);
    }

    const newMessages: Message[] = [];
    const userPrompt = firstMessageBody;

    const systemInstructionsTemplate = `
    You are a helpful ai assistant for course creator, your name is Learmo Ai. Your task is to provide a detailed and helpful answers for the content creators The responses should be concise and easy to read.
    Now start the conversation with a welcome message.
    `;

    const systemInstructionsMessage: Message = new Message({
      from: ChatMessageRole.System,
      text: systemInstructionsTemplate,
      type: 'text',
      isHidden: true,
    });

    newMessages.push(systemInstructionsMessage);
    if (userPrompt) {
      const userPromptMessage: Message = new Message({
        from: ChatMessageRole.User,
        text: userPrompt,
        type: 'text',
        isHidden: false,
      });
      newMessages.push(userPromptMessage);
    }

    // const currentDate = new Date().toLocaleDateString('en-GB', {
    //   day: 'numeric',
    //   month: 'long',
    //   year: 'numeric',
    // });


    this.sendMessage(
      newChat.id,
      newMessages,
    );

    return newChat;
  }

  deleteChat(chatId: string): void {
    const index = this.chats.findIndex((c) => c.id === chatId);

    if (index !== -1) {
      this.chats.splice(index, 1);

      // Update the BehaviorSubject with currentChat data
      if (this.currentChat.id === chatId && this.chats.length) {
        this.currentChat = this.chats[0];
      }

      // Update the storage
      this.storageService.setItem('chats', this.chats);
    }
  }

  // Implementation for generating unique id
 

  private buildOpenAiHistory(
    messages: Message[]
  ): ChatCompletionMessageParam[] {
    //   ChatCompletionSystemMessageParam
    // ChatCompletionUserMessageParam
    // ChatCompletionAssistantMessageParam

    let history = messages.map((msg) => {
      let message:
        | ChatCompletionMessageParam
        | ChatCompletionSystemMessageParam
        | ChatCompletionUserMessageParam
        | ChatCompletionAssistantMessageParam;
      switch (msg.from) {
        case ChatMessageRole.Assistant:
          message = {
            role: 'assistant',
            content: msg.text,
          };
          break;
        case ChatMessageRole.User:
          message = {
            role: 'user',
            content: msg.text,
          };
          break;
        case ChatMessageRole.System:
          message = {
            role: 'system',
            content: msg.text,
          };
          break;

        default:
          break;
      }

      return message;
    });

    return history;
  }

  sendMessage(
    chatId: string,
    messages: Message[],
  ): void {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    if (messages && messages.length > 0) {
      this.chats[chatIndex].messages =
        this.chats[chatIndex].messages.concat(messages);
      // messages.forEach((message) => {

      //   this.chats[chatIndex].messages.push(newMessage);
      // })
    }

    // if (this.chats[chatIndex].messages[0].from === 'user') {
    //   const firstMessage = this.chats[chatIndex].messages[0].text;
    //   const title =
    //     firstMessage.length > 16
    //       ? `${firstMessage.slice(0, 16)}...`
    //       : firstMessage;
    //   this.setChatTitle(chatId, title);
    // }

    const fullHistoryWithNewMessage = this.buildOpenAiHistory(
      this.chats[chatIndex].messages
    );

    let oai_api_key = this.storageService.getItem<string>('oai_api_key');
    this.sendMessageOAI(chatIndex, fullHistoryWithNewMessage)
      .catch((err) => {
        console.error('Failed to send message to OAI:', err);
      })
      .then(() => {
        if (this.chats[chatIndex].messages.length < 3) return;
        const context = `${this.chats[chatIndex].messages
          .slice(2)
          .map((msg) => msg.text)}`;

        this.generateChatTitle(chatId, `${context}`);
      });

    this.scrollToChatEnd();
  }

  generateChatTitle(chatId: string, context: string) {
    const prompt = `
    ---BEGIN Conversation---
    ${context}
    ---END Conversation---

    Generate a concise title for the conversation in just one short sentence ends with emoji and maximum 3 words.<|eot_id|><|start_header_id|>assistant<|end_header_id|>`;
    let generatedTitle = '';
    this.aiService.getCompletion(`${prompt}`).subscribe((stream) => {
      
      generatedTitle += stream;
      if (!!generatedTitle.length) {
        this.updateChatConfig(chatId, generatedTitle);
      }
    });
  }

  scrollToChatEnd() {
    setTimeout(() => {
      let element = document.getElementById('chat-area');
      element.scrollTop = element.scrollHeight;
    }, 100);
  }
  async sendMessageOAI(
    chatIndex: number,
    messages: ChatCompletionMessageParam[],
  ) {
    let aiResponseBase: Message = new Message({
      from: ChatMessageRole.Assistant,
      text: '',
      complete: false,
      type: 'text',
      isHidden: false,
    });

    this.chats[chatIndex].messages.push(aiResponseBase);

    this.aiService
      .getChatCompletionRequest({
        model: undefined,
        messages: messages,
        stream: true,
      })
      .subscribe((stream) => {
        const decoder = new TextDecoder('utf-8');
        aiResponseBase.text += stream;
        aiResponseBase.firstEmitIsHere = true;

        this.storageService.setItem('chats', this.chats);
      });
    // this.aiService.getChatCompletionRequest(history, message)
    //   .then(async (stream) => {
    //     const decoder = new TextDecoder('utf-8');

    //     let receivedString = '';
    //     let isNewMessage = true;

    //     let newMessage: Message = {
    //       id: this.generateId(),
    //       from: 'bot',
    //       text: '',
    //       complete: false,
    //       type: 'text',
    //       isHidden,
    //       partToShow
    //     };

    //     for await (const chunk of stream) {
    //       receivedString += chunk.choices[0].delta.content;
    //       if (chunk.choices[0].finish_reason === 'stop') {
    //         receivedString += '\n';
    //       }
    //       const newLineIndex = receivedString.indexOf('\n');
    //       const line = receivedString.slice(0, newLineIndex);
    //       receivedString = receivedString.slice(newLineIndex + 1);

    //       if (chunk.choices && chunk.choices[0] && chunk.choices[0].delta) {
    //         if (isNewMessage) {
    //           let delta = chunk.choices[0].delta.content;
    //           newMessage.text = delta;
    //           this.chats[chatIndex].messages.push(newMessage);
    //           isNewMessage = false;
    //         } else {
    //           let delta = chunk.choices[0].delta.content;
    //           if (delta && delta !== '') {
    //             newMessage.text += delta;
    //             newMessage.complete = chunk.choices[0].finish_reason === 'stop';
    //           }
    //         }

    //         this.storageService.setItem('chats', this.chats);
    //       }
    //       this.scrollToChatEnd();
    //     }
    //   });
  }

  updateMessage(chatId: string, updatedMessage: Message) {
    // Find the chat based on chatId
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);

    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    // Within the found chat, find the message index using updatedMessage.id
    const messageIndex = this.chats[chatIndex].messages.findIndex(
      (msg) => msg.id === updatedMessage.id
    );

    if (messageIndex < 0) {
      console.error(`No message found with id: ${updatedMessage.id}`);
      return;
    }

    this.chats[chatIndex].messages[messageIndex] = updatedMessage;
    this.storageService.setItem('chats', this.chats);

    this.refreshChatAfterEdit(chatId, updatedMessage.id).catch((err) =>
      console.error('Failed to refresh chat:', err)
    );
  }

  async refreshChatAfterEdit(
    chatId: string,
    updatedMessageId: string
  ): Promise<void> {
    const chatIndex = this.chats.findIndex((chat) => chat.id === chatId);
    if (chatIndex < 0) {
      console.error(`No chat found with id: ${chatId}`);
      return;
    }

    const messageIndex = this.chats[chatIndex].messages.findIndex(
      (msg) => msg.id === updatedMessageId
    );

    this.chats[chatIndex].messages.splice(messageIndex + 1);

    let history = this.chats[chatIndex].messages.map((msg) => ({
      role: msg.from === 'user' ? 'user' : 'assistant',
      content: msg.text,
    }));

    // const productId = this.chats[chatIndex].product_id;

    const lastMessage = history[history.length - 1].content;

    // Fetch new sequence of responses based on the updated history
    if (lastMessage) {
      let oai_api_key =
        this.storageService.getItem<string>('oai_api_key') || 'NA';

      const messages = this.buildOpenAiHistory(this.chats[chatIndex].messages);
      await this.sendMessageOAI(chatIndex, messages);
    }
  }
}
