// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item.active, .dropdown-item:active {
  color: #141414 !important;
  background-color: #dbdbdb !important;
}

/* .dropdown-item:focus {
  background-color: #fff !important;
} */

.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {
  color: #F64E60 !important;
  background-color: #dbdbdb !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm93bmVyLWRyb3AuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHlCQUF5QjtFQUN6QixvQ0FBb0M7QUFDdEM7O0FBRUE7O0dBRUc7O0FBRUg7RUFDRSx5QkFBeUI7RUFDekIsb0NBQW9DO0FBQ3RDIiwiZmlsZSI6Im93bmVyLWRyb3AuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5kcm9wZG93bi1pdGVtLmFjdGl2ZSwgLmRyb3Bkb3duLWl0ZW06YWN0aXZlIHtcbiAgY29sb3I6ICMxNDE0MTQgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2RiZGJkYiAhaW1wb3J0YW50O1xufVxuXG4vKiAuZHJvcGRvd24taXRlbTpmb2N1cyB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmYgIWltcG9ydGFudDtcbn0gKi9cblxuLmRyb3Bkb3duLWl0ZW0udGV4dC1kYW5nZXIuYWN0aXZlLCAuZHJvcGRvd24taXRlbS50ZXh0LWRhbmdlcjphY3RpdmUge1xuICBjb2xvcjogI0Y2NEU2MCAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZGJkYmRiICFpbXBvcnRhbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/user-drop/owner-drop/owner-drop.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;;GAEG;;AAEH;EACE,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA,gyBAAgyB","sourcesContent":[".dropdown-item.active, .dropdown-item:active {\n  color: #141414 !important;\n  background-color: #dbdbdb !important;\n}\n\n/* .dropdown-item:focus {\n  background-color: #fff !important;\n} */\n\n.dropdown-item.text-danger.active, .dropdown-item.text-danger:active {\n  color: #F64E60 !important;\n  background-color: #dbdbdb !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
