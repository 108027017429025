// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-item {
    transition: all 0.2s ease-in-out;
}
.plan-item:not(.active) {
    cursor: pointer;
    
    
}

.plan-item:not(.active):hover {
    transform: scale(1.03);
}

.plan-item.active {
    transform: translateY(-5px) scale(1.05);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldHRpbmdzLXBsYW4taXRlbS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksZ0NBQWdDO0FBQ3BDO0FBQ0E7SUFDSSxlQUFlOzs7QUFHbkI7O0FBRUE7SUFDSSxzQkFBc0I7QUFDMUI7O0FBRUE7SUFDSSx1Q0FBdUM7QUFDM0MiLCJmaWxlIjoic2V0dGluZ3MtcGxhbi1pdGVtLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGxhbi1pdGVtIHtcbiAgICB0cmFuc2l0aW9uOiBhbGwgMC4ycyBlYXNlLWluLW91dDtcbn1cbi5wbGFuLWl0ZW06bm90KC5hY3RpdmUpIHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG4gICAgXG4gICAgXG59XG5cbi5wbGFuLWl0ZW06bm90KC5hY3RpdmUpOmhvdmVyIHtcbiAgICB0cmFuc2Zvcm06IHNjYWxlKDEuMDMpO1xufVxuXG4ucGxhbi1pdGVtLmFjdGl2ZSB7XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKC01cHgpIHNjYWxlKDEuMDUpO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/profile/subscription-and-billing-page/plans/settings-plan-item/settings-plan-item.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;AACA;IACI,eAAe;;;AAGnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uCAAuC;AAC3C;AACA,gtBAAgtB","sourcesContent":[".plan-item {\n    transition: all 0.2s ease-in-out;\n}\n.plan-item:not(.active) {\n    cursor: pointer;\n    \n    \n}\n\n.plan-item:not(.active):hover {\n    transform: scale(1.03);\n}\n\n.plan-item.active {\n    transform: translateY(-5px) scale(1.05);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
