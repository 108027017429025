// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messenger-modal .modal-dialog {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: 0px;
}

.messenger-modal .modal-content {
  max-height: 100vh;
}

.messenger-modal .modal-content .card-body {
  max-height: 40vh;
  overflow: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lc3Nlbmdlci1jaGF0LXBvcHVwLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFlO0VBQ2YsWUFBWTtFQUNaLFdBQVc7RUFDWCxXQUFXO0FBQ2I7O0FBRUE7RUFDRSxpQkFBaUI7QUFDbkI7O0FBRUE7RUFDRSxnQkFBZ0I7RUFDaEIsY0FBYztBQUNoQiIsImZpbGUiOiJtZXNzZW5nZXItY2hhdC1wb3B1cC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1lc3Nlbmdlci1tb2RhbCAubW9kYWwtZGlhbG9nIHtcbiAgcG9zaXRpb246IGZpeGVkO1xuICBib3R0b206IDIwcHg7XG4gIHJpZ2h0OiAyMHB4O1xuICBtYXJnaW46IDBweDtcbn1cblxuLm1lc3Nlbmdlci1tb2RhbCAubW9kYWwtY29udGVudCB7XG4gIG1heC1oZWlnaHQ6IDEwMHZoO1xufVxuXG4ubWVzc2VuZ2VyLW1vZGFsIC5tb2RhbC1jb250ZW50IC5jYXJkLWJvZHkge1xuICBtYXgtaGVpZ2h0OiA0MHZoO1xuICBvdmVyZmxvdzogYXV0bztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/messenger/messenger-chat-popup/messenger-chat-popup.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA,4uBAA4uB","sourcesContent":[".messenger-modal .modal-dialog {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  margin: 0px;\n}\n\n.messenger-modal .modal-content {\n  max-height: 100vh;\n}\n\n.messenger-modal .modal-content .card-body {\n  max-height: 40vh;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
