// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdldC1wcm9kdWN0LWxpbmsuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0Usb0NBQW9DO0FBQ3RDIiwiZmlsZSI6ImdldC1wcm9kdWN0LWxpbmsuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuXG4uaXAtc3dpdGNoLWZpeCB7XG4gIG1hcmdpbi10b3A6MCAhaW1wb3J0YW50O1xufVxuXG4uc3dpdGNoIGlucHV0OmRpc2FibGVkIH4gc3BhbjpiZWZvcmUge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZGFkYWRhICFpbXBvcnRhbnQ7XG59XG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/product/product-item/get-product-link/get-product-link.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;;AAGA,ofAAof","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
