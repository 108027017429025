import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[ScrollSnap]'
})
export class ScrollSnapDirective implements AfterViewInit {
  @Input() snapClass?: string;
  snapDataAttribute?: string[] = ["data-scrollsnap"];
  lastIndex: any;
  @Input('snapDataAttribute') set setSnapDataAttribute (value: string | string[]) {
    if ((typeof value == 'string')) {
      this.snapDataAttribute = [value];
    }
    else {
      this.snapDataAttribute = value
    }
  }
  @Output() snappedIndex = new EventEmitter<number>();
  @Output() snappedItemData = new EventEmitter<any>();

  private snapChildren: HTMLElement[] = [];

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.collectSnapChildren();
    this.calculateAndGetData(true);
  }

  private collectSnapChildren(): void {
    const container = this.el.nativeElement;
    if (this.snapClass) {
      this.snapChildren = Array.from(container.getElementsByClassName(this.snapClass)) as HTMLElement[];
    } else {
      this.snapChildren = Array.from(container.children).filter((child: Element) => 
        window.getComputedStyle(child).scrollSnapAlign !== 'none'
      ) as HTMLElement[];
    }
  }

  // @HostListener('scroll', ['$event'])
  // onScroll(event: Event): void {
  //   this.calculateAndGetData()
  // }

  @HostListener('scroll', ['$event'])
  onScrollEnd(event: Event): void {
    this.calculateAndGetData()
  }

  private calculateAndGetData(forceEmit = false): void {
    let dataEmitObject: Object = {};
    const container = this.el.nativeElement;
    const containerRect = container.getBoundingClientRect();
    const containerCenter = containerRect.top + containerRect.height / 2;

    let {index, child} = this.findNearestIndex(containerCenter);
    
    // 

    if (index != this.lastIndex || forceEmit) {
    this.snappedIndex.emit(index);
    if (!!child) {
      this.snapDataAttribute.forEach((dataAttribute) => {
        const dataValue = (child as HTMLElement).getAttribute(dataAttribute);
        if (!!dataValue) {
          dataEmitObject[dataAttribute] = dataValue;
        }
      });

      this.snappedItemData.emit(!!dataEmitObject ? dataEmitObject : index);
      
    }
    this.lastIndex = index;
  }
  }

  private findNearestIndex(containerCenter: number): {index, child} {
    let nearestIndex = -1;
    let nearestChild = null;
    let minDistance = Infinity;
    // let debuggingTable = [];

    this.snapChildren.forEach((child, index) => {
      const childRect = child.getBoundingClientRect();
      const childCenter = childRect.top + childRect.height / 2;
      const distance = Math.abs(containerCenter - childCenter);

      // debuggingTable.push({index, distance, minDistance});
      
      if (distance < minDistance) {
        minDistance = distance;
        nearestIndex = index;
        nearestChild = child;
      }
    });

    // console.table(debuggingTable);
    
    return {index: nearestIndex, child: nearestChild};
  }
}
