// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.image-loaded-style {
  background-size: 100% !important;
  background-position: top center !important;
}

.loading-style {
  background-size: 37% !important;
  background-position: center center !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyZWF0ZS1ibG9nLXBvc3QtcG9wdXAuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0UsZ0NBQWdDO0VBQ2hDLDBDQUEwQztBQUM1Qzs7QUFFQTtFQUNFLCtCQUErQjtFQUMvQiw2Q0FBNkM7QUFDL0MiLCJmaWxlIjoiY3JlYXRlLWJsb2ctcG9zdC1wb3B1cC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG5cbi5pcC1zd2l0Y2gtZml4IHtcbiAgbWFyZ2luLXRvcDowICFpbXBvcnRhbnQ7XG59XG5cbi5pbWFnZS1sb2FkZWQtc3R5bGUge1xuICBiYWNrZ3JvdW5kLXNpemU6IDEwMCUgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1wb3NpdGlvbjogdG9wIGNlbnRlciAhaW1wb3J0YW50O1xufVxuXG4ubG9hZGluZy1zdHlsZSB7XG4gIGJhY2tncm91bmQtc2l6ZTogMzclICFpbXBvcnRhbnQ7XG4gIGJhY2tncm91bmQtcG9zaXRpb246IGNlbnRlciBjZW50ZXIgIWltcG9ydGFudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/portal/view/portal-blog/portal-blog-posts/create-blog-post-popup/create-blog-post-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,0CAA0C;AAC5C;;AAEA;EACE,+BAA+B;EAC/B,6CAA6C;AAC/C;;AAEA,wvBAAwvB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.image-loaded-style {\n  background-size: 100% !important;\n  background-position: top center !important;\n}\n\n.loading-style {\n  background-size: 37% !important;\n  background-position: center center !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
