// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.ip-switch-fix {
  margin-top:0 !important;
}

.switch input:disabled ~ span:before {
  background-color: #dadada !important;
}

.ip-table tbody td {
  padding: 0.4em 0.75em !important;
}

.ip-table th {
  padding: 1em 0.75em !important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlZnVuZC1pdGVtLXBvcHVwLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6Ijs7QUFFQTtFQUNFLHVCQUF1QjtBQUN6Qjs7QUFFQTtFQUNFLG9DQUFvQztBQUN0Qzs7QUFFQTtFQUNFLGdDQUFnQztBQUNsQzs7QUFFQTtFQUNFLDhCQUE4QjtBQUNoQyIsImZpbGUiOiJyZWZ1bmQtaXRlbS1wb3B1cC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXG5cbi5pcC1zd2l0Y2gtZml4IHtcbiAgbWFyZ2luLXRvcDowICFpbXBvcnRhbnQ7XG59XG5cbi5zd2l0Y2ggaW5wdXQ6ZGlzYWJsZWQgfiBzcGFuOmJlZm9yZSB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNkYWRhZGEgIWltcG9ydGFudDtcbn1cblxuLmlwLXRhYmxlIHRib2R5IHRkIHtcbiAgcGFkZGluZzogMC40ZW0gMC43NWVtICFpbXBvcnRhbnQ7XG59XG5cbi5pcC10YWJsZSB0aCB7XG4gIHBhZGRpbmc6IDFlbSAwLjc1ZW0gIWltcG9ydGFudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/refund/refund-item/refund-item-popup/refund-item-popup.component.css"],"names":[],"mappings":";;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;AAEA,otBAAotB","sourcesContent":["\n\n.ip-switch-fix {\n  margin-top:0 !important;\n}\n\n.switch input:disabled ~ span:before {\n  background-color: #dadada !important;\n}\n\n.ip-table tbody td {\n  padding: 0.4em 0.75em !important;\n}\n\n.ip-table th {\n  padding: 1em 0.75em !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
