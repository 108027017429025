// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinking-cursor {
  font-weight: 100;
  font-size: inherit;
  color: #2e3d48;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.cursor-block {
  background-color: #616161;
  width: 7px;
  display: inline-block;
  /* min-height: 12px; */
  height: 12px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFpLWN1cnNvci5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQWdCO0VBQ2hCLGtCQUFrQjtFQUNsQixjQUFjO0VBQ2QscUNBQXFDO0FBQ3ZDOztBQUVBO0VBQ0U7O0lBRUUsVUFBVTtFQUNaO0VBQ0E7SUFDRSxVQUFVO0VBQ1o7QUFDRjs7QUFFQTtFQUNFLHlCQUF5QjtFQUN6QixVQUFVO0VBQ1YscUJBQXFCO0VBQ3JCLHNCQUFzQjtFQUN0QixZQUFZO0FBQ2QiLCJmaWxlIjoiYWktY3Vyc29yLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYmxpbmtpbmctY3Vyc29yIHtcbiAgZm9udC13ZWlnaHQ6IDEwMDtcbiAgZm9udC1zaXplOiBpbmhlcml0O1xuICBjb2xvcjogIzJlM2Q0ODtcbiAgYW5pbWF0aW9uOiAxcyBibGluayBzdGVwLWVuZCBpbmZpbml0ZTtcbn1cblxuQGtleWZyYW1lcyBibGluayB7XG4gIGZyb20sXG4gIHRvIHtcbiAgICBvcGFjaXR5OiAwO1xuICB9XG4gIDUwJSB7XG4gICAgb3BhY2l0eTogMTtcbiAgfVxufVxuXG4uY3Vyc29yLWJsb2NrIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogIzYxNjE2MTtcbiAgd2lkdGg6IDdweDtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAvKiBtaW4taGVpZ2h0OiAxMnB4OyAqL1xuICBoZWlnaHQ6IDEycHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/partials/ai/ai-cursor/ai-cursor.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE;;IAEE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,qBAAqB;EACrB,sBAAsB;EACtB,YAAY;AACd;;AAEA,o7BAAo7B","sourcesContent":[".blinking-cursor {\n  font-weight: 100;\n  font-size: inherit;\n  color: #2e3d48;\n  animation: 1s blink step-end infinite;\n}\n\n@keyframes blink {\n  from,\n  to {\n    opacity: 0;\n  }\n  50% {\n    opacity: 1;\n  }\n}\n\n.cursor-block {\n  background-color: #616161;\n  width: 7px;\n  display: inline-block;\n  /* min-height: 12px; */\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
