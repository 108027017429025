import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';

import { Router } from '@angular/router';



import { ApiService } from '../../../../services';

import { environment } from 'src/environments/environment';
import { AppwriteException } from 'appwrite';
import { mergeMap, map, catchError } from 'rxjs';

import * as AuthActions from '../auth/auth.actions';
import * as RegisterActions from '../register/register.actions';
import * as ProfileActions from './profile.actions';
import { UpdateUserTwoFaPayload } from 'src/app/models';
import { LoginMethodsEnum } from 'src/app/components/auth/login-alt/user-login-form-alt/user-login-form-alt.component';
import { LoginMethodStatusEnum } from 'src/app/components/profile/security-settings/login-methods/login-methods.component';

export class LoginMethodResult {
  method: LoginMethodsEnum;
  enabled: LoginMethodStatusEnum;
  reason: string;
}
@Injectable()
export class ProfileEffects {
  private currentUserStorageKey = environment.storageKeys.currentUserStorageKey;
  private appwriteProfileStorageKey =
    environment.storageKeys.appwriteProfileStorageKey;
  private tokenStorageKey = environment.storageKeys.tokenStorageKey;
  constructor(
    private actions$: Actions,
    private api: ApiService,

    private router: Router,
    private store: Store
  ) {}




  updateUserProfile$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProfileActions.userProfileActions.update),
    mergeMap(({ payload }) =>
    this.api.updateProfile(payload, (error:any)=> {throw error}).pipe(
        map((response) => ProfileActions.userProfileActions.updateSuccess({ learmoProfile: response })),
        catchError(async (error: any) => ProfileActions.userProfileActions.updateFailure({ error }))
        )
      )
    )
  )




  updateUserEmail$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProfileActions.userEmailActions.update),
    mergeMap(({ payload }) =>
    this.api.changeEmailRequest(payload, (error:any)=> {throw error}).pipe(
        map((response) => ProfileActions.userEmailActions.updateSuccess({ response })),
        catchError(async (error: any) => {
          console.log(error)
          return ProfileActions.userEmailActions.updateFailure({ error })
        })
        )
      )
    )
  )






  enable2FA$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProfileActions.enable2FAActions.enable),
    mergeMap(() => {
      const payload: UpdateUserTwoFaPayload = {twofa: 1};

      return this.api.updateUserSettings(payload, (error:any)=> {throw error}).pipe(
        map((response) => ProfileActions.enable2FAActions.enableSuccess()),
        catchError(async (error: any) => {
          console.log(error)
          return ProfileActions.enable2FAActions.enableFailure({ error })
        })
        )
      }
      )
    )
  )


  getUserLoginMethods$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProfileActions.userLoginMethodConfigActions.get),
    mergeMap(() => {
      return this.api.getLoginMethodsStatus( (error:any)=> {throw error}).pipe(
        map((response: LoginMethodResult[]) => {
          
          return ProfileActions.userLoginMethodConfigActions.getSuccess({loginMethodsResults: response})
        }),
        catchError(async (error: any) => {
          console.log(error)
          return ProfileActions.userLoginMethodConfigActions.getFailure({ error })
        })
        )
      }
      )
    )
  )


  updateUserLoginMethod$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ProfileActions.changeLoginMethodConfigActions.change),
    mergeMap(({method, enabled}) => {
      let methodEnabled = enabled;
      let methodStatus = !!enabled ? LoginMethodStatusEnum.enabled : LoginMethodStatusEnum.disabled
      return this.api.updateLoginMethodsStatus(method, methodEnabled, (error:any)=> {throw error}).pipe(
        map((response: LoginMethodResult[]) => {
          
          return ProfileActions.changeLoginMethodConfigActions.changeSuccess({method, enabled: methodStatus})
        }),
        catchError(async (error: any) => {
          console.log(error)
          return ProfileActions.changeLoginMethodConfigActions.changeFailure({ error })
        })
        )
      }
      )
    )
  )
}
