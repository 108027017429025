// effects/products.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators'; // Import operators correctly
import { of } from 'rxjs';
import { ApiService } from '../../../services';
import {

  NewContactActions,
  ContactsActions,

} from './contacts.actions';
import { ContactItem } from 'src/app/components/user-dashboard/contacts/models/contacts';
import { CreateContactDto } from 'src/app/components/user-dashboard/contacts/create/dto/create-contact.dto';

@Injectable()
export class ContactsEffects {
  constructor(private actions$: Actions, private api: ApiService) {}

  loadContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsActions.load), // Listen for the loadProducts action
      mergeMap(() => {
        
        return this.api.getContacts().pipe(
          // Use pipe method correctly within mergeMap
          map((contacts) => ContactsActions.loadSuccess({ contacts })),
          catchError((error) => of(ContactsActions.loadFailure({ error })))
        );
      })
    )
  );

  createNewContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewContactActions.loadNewContact), // Listen for the loadProducts action
      mergeMap(({ createPayload }) => {
        let payload = {
          name: createPayload.name,
          email: createPayload.email,
          description: createPayload.description,
          // avatar_id: createPayload.avatar_id
        };
        return this.api.createContact(payload, createPayload.type).pipe(
          // Use pipe method correctly within mergeMap
          map((response: ContactItem) =>
            NewContactActions.loadSuccess({ contact: response })
          ),
          catchError((error) => of(NewContactActions.loadFailure({ error })))
        );
      })
    )
  );
}
