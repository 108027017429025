import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ThemeMediaTypeEnum,
  TemplateMediaItem,
  LoginMediaItem,
} from 'src/app/components/user-dashboard/portal/portal.model';

import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  SwiperOptions,
  Swiper,
} from 'swiper';

SwiperCore.use([Autoplay, Pagination, Navigation]);

@Component({
  selector: 'app-welcome-onboarding-gallery',
  templateUrl: './welcome-onboarding-gallery.component.html',
  styleUrls: ['./welcome-onboarding-gallery.component.css'],
})
export class WelcomeOnboardingGalleryComponent implements OnInit {
  @Input() firstName: string;
  // @Input() mainImage: TemplateMediaItem;
  // @Input() images: TemplateMediaItem[];

  @Output() onDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  themeMediaTypeEnum = ThemeMediaTypeEnum;
  thumbsSwiper: any;
  swiperController: SwiperCore;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    autoplay: false,
    navigation: {
      enabled: false,
    },
    direction: 'horizontal',
    pagination: {
      clickable: true,
      dynamicBullets: false,
      bulletActiveClass: 'swiper-pagination-bullet-active w-[25px] rounded',
      bulletClass: 'swiper-pagination-bullet',
      clickableClass: 'swiper-pagination-clickable',
      currentClass: 'swiper-pagination-current',
    },
    effect: 'slide',
    grabCursor: false,
    allowTouchMove: false,
    centeredSlides: true,
    zoom: {
      maxRatio: 2,
      toggle: true,
    },
    // preventClicks: true,
    // preventClicksPropagation: true,
    on: {
      slideChange: this.onSlideChange,
    },
  };
  _loadedImages: boolean = true;
  _images: LoginMediaItem[] = [
    {
      "title": "🌟 Your Content, Your Product 🌟",
      "content": "Did you know that your knowledge and expertise can be your most valuable product? With Learmo, you can transform your content into a thriving online course that inspires and educates. Let's unlock the potential of your content together.",
      "image": "assets/images/svg/system/onboarding/product/creation.svg?rand=1.2",
      "type": ThemeMediaTypeEnum.image
    },
    {
      title: '🧠 AI-Powered Course Design, Meet Your AI Partner',
      content: `Learmo AI harnesses the latest in artificial intelligence to assist you in designing courses that captivate your audience. It understands learner needs and preferences, helping you create content that resonates.`,
      image: 'assets/images/svg/system/onboarding/product/digitalization.svg',
      type: ThemeMediaTypeEnum.image
    },
    {
      "title": "🚀 Sell Your Course with Learmo",
      "content": "Ready to launch and sell your course? Learmo provides you with the tools and guidance you need to start making sales and sharing your knowledge with the world.",
      "image": "assets/images/svg/system/onboarding/product/selling.svg?rand=1.2",
      "type": ThemeMediaTypeEnum.image
    }
  ];

  constructor() {}

  @Input('images') set images(value: LoginMediaItem[]) {
    // this._images = value;
    this._images.push(...value);

    this._loadedImages = true;
  }

  goNext() {
    this.swiperController.slideNext();
  }

  goBack() {
    this.swiperController.slidePrev();
  }

  onSwiper(value) {
    this.swiperController = value;
  }
  onSlideChange(swiper: SwiperCore) {
    // if (swiper.slides.length == swiper.activeIndex +1) {
    // }
    // else
  }

  ngOnInit(): void {
    // this._images = [
    //   new ImageItem({ src: this.mainImage.url, thumb: this.mainImage.url }),
    //   // ... more items
    // ];
  }
}
