import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, TemplateRef, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { EditorConfigService } from '../../services/editor-config.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CourseModel } from 'src/app/models/portal';
import { AiService } from '../../services/ai.service';
import * as cheerio from 'cheerio';


export enum AIGeneratorTypeEnum {
  none = 'none',
  short_description = 'short_description',
  long_description = 'long_description',
  question = 'question'
}
@Component({
  selector: 'app-learmo-editor',
  templateUrl: './learmo-editor.component.html',
  styleUrls: ['./learmo-editor.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LearmoEditorComponent),
      multi: true
    }
  ]
})
export class LearmoEditorComponent implements  ControlValueAccessor, OnInit, AfterViewInit {
  htmlDebugContent: string = "";
  aIGeneratorTypeEnum = AIGeneratorTypeEnum;
  aiStatus: 'none' | 'loading' | 'done'= 'none'
  @Input() content: string = '';
  @Output() contentChange: EventEmitter<string> = new EventEmitter<string>();


  // TO BE IMPLEMENTED
  @Input() stickEditorHead: boolean = true;
  @Input() hideBuiltInAiButton: boolean = false;

  @Input() containerClass: string;
  @Input() editorClass: string;
  @Input() editorID: string;
  @Input() placeholder: string;
  @ViewChild('editorContainer') modalContent: TemplateRef<any>;
  @Input() modalRef: NgbModalRef;

  @Input() aiType: AIGeneratorTypeEnum = AIGeneratorTypeEnum.none;

  @Input() prompt: (inputValue:string, auxiliaryValue?: string)=> string = (inputValue: string): string => {
    return `Generate a fully html formatted, long description for webpage with emojis including overview and objectives and methodology and prerequisites and thoughts for a course about '${inputValue}':`
  }

  modalMode: boolean = false;

  @Input() generatorInputValue: string;

  @Input() inputFormControl: FormControl<any> | any;


  // Implement ControlValueAccessor methods
  private _onChange: (value: string) => void = (value: string) => {
    this.contentChange.emit(value);
  };
  private _onTouched: () => void = () => { };

  constructor(public editorConfig: EditorConfigService, private modalService: NgbModal, private ai: AiService) {
    if (!this.editorID) {
      this.editorID = this.generateUniqueString();
    }
  }

  ngOnInit() {
    
  }

  writeValue(content: string): void {
    this.content = content;
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  onInputChange(event: any) {


    this._onChange(this.content); // Notify parent component about the content change
  }

  decodeHTMLEntities(text) {
    const element = document.createElement("div");
    element.innerHTML = text;
    return element.textContent;
  }
  appendToContent(value: string) {
    let TempContent = this.decodeHTMLEntities(this.content)
    TempContent += value;
    this.content = TempContent;
    
    this._onChange(this.content); // Notify parent component about the content change
  }

  ngAfterViewInit() {
    setTimeout(() => {
        this.initFullScreenButton();
    }, 500);
  }

  generateUniqueString(): string {
    // Generate a random string (you can customize the length and characters)
    const randomString = Math.random().toString(36).substring(2, 8);

    // Get the current timestamp
    const timestamp = new Date().getTime();

    // Combine the random string and timestamp to create a unique short string
    const uniqueString = randomString + timestamp;

    return uniqueString;
  }


  initFullScreenButton() {
       // Get the custom button element and the Quill editor container
       const customButton = document.getElementById(`${this.editorID}_custom-button`);
       const quillEditor = document.getElementById(this.editorID);
       const quillEditorContainer = quillEditor.querySelector('.ql-toolbar');



       if (quillEditorContainer) {
         // Add the custom button element to the Quill toolbar
         quillEditorContainer.appendChild(customButton).classList.remove('d-none');
       }

      //  // Add event listener to the custom button
      //  customButton.addEventListener('click', () => {
      //    
      //    // Implement your custom button's functionality here
      //  });
  }

    // Add custom button functionality here
    onCustomButtonClick() {
      
      this.open();
      // Implement your custom button's functionality here
    }




    closeModal() {
      this.modalRef.dismiss();
    }
    // Affiliate Links List

    // Modal Open
    async open(content?) {
      this.onOpenModal();
      this.modalRef = this.modalService.open(this.modalContent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        fullscreen: true,
        backdrop: true,
        centered: true,
        animation: false,
        // modal-animate-in
        windowClass: 'global-modal-class learmo-editor-modal',
        beforeDismiss: ()=> {
          this.modalMode = false;
          return true;
        }
      });

    }

    async onOpenModal() {
      this.modalMode = true;
      // this.loaded = false;
      // await this.getCurrentAnnouncement();
      // this.loaded = true;
    }

    // decodeHTMLEntities(text) {
    //   const element = document.createElement("div");
    //   element.innerHTML = text;
    //   return element.innerHTML;
    // }

    async startGenerating(
      courseTitle: string = this.generatorInputValue
    ) {
      this.aiStatus = 'loading'
  
      this.content = this.htmlDebugContent = '';
      
      const prompt = this.prompt(courseTitle);
      

      this.ai.getCompletion(prompt, true).subscribe({
        next: (response) => {
          // 
          this.htmlDebugContent += response;
          this.content += response;
          const completions = response
          
          if (completions != '\n') {
            if (!this.content.length) {
              this.content = ''
            }
          }
        },
        error: (error) => {
          // Handle the error
        },
        complete: () => {
          this.htmlDebugContent = this.htmlDebugContent;

          const $ = cheerio.load(this.content);
          $('img, iframe').remove();
          const bodyContent = $('body').html();
            
          this.content = bodyContent;
          this.aiStatus = 'done'
          // this.productCreateService.wizardData.courseInput.shortDescription =
          //   this.productCreateService.wizardData.courseInput.shortDescription.replace(
          //     /['"]+/g,
          //     ''
          //   );
          // this.changeAiStatus(DescriptionEnterStatusEnum.manual);
          // this.resultsSync--;
        },
      });
    }

}
