// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-container .message-actions {
    opacity: 0;
    max-height: 0;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}


.message-container:hover .message-actions {
    opacity: 1;
    max-height: 50px;
    transform: translateY(0);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItbWVzc2FnZS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksVUFBVTtJQUNWLGFBQWE7SUFDYiw0QkFBNEI7SUFDNUIsZ0NBQWdDO0FBQ3BDOzs7QUFHQTtJQUNJLFVBQVU7SUFDVixnQkFBZ0I7SUFDaEIsd0JBQXdCO0FBQzVCIiwiZmlsZSI6InVzZXItbWVzc2FnZS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1lc3NhZ2UtY29udGFpbmVyIC5tZXNzYWdlLWFjdGlvbnMge1xuICAgIG9wYWNpdHk6IDA7XG4gICAgbWF4LWhlaWdodDogMDtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVkoLTEwMCUpO1xuICAgIHRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UtaW4tb3V0O1xufVxuXG5cbi5tZXNzYWdlLWNvbnRhaW5lcjpob3ZlciAubWVzc2FnZS1hY3Rpb25zIHtcbiAgICBvcGFjaXR5OiAxO1xuICAgIG1heC1oZWlnaHQ6IDUwcHg7XG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVZKDApO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/user-dashboard/chat-with-ai/partials/chat-area/partials/user-message/user-message.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,4BAA4B;IAC5B,gCAAgC;AACpC;;;AAGA;IACI,UAAU;IACV,gBAAgB;IAChB,wBAAwB;AAC5B;AACA,ouBAAouB","sourcesContent":[".message-container .message-actions {\n    opacity: 0;\n    max-height: 0;\n    transform: translateY(-100%);\n    transition: all 0.3s ease-in-out;\n}\n\n\n.message-container:hover .message-actions {\n    opacity: 1;\n    max-height: 50px;\n    transform: translateY(0);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
